import React from 'react';
import {
	FormControlProps,
	FormLabelProps,
	TextareaProps,
	FormControl,
	FormLabel,
	Textarea,
	FormErrorMessage,
} from '@chakra-ui/react';

interface IInputTextareaProps extends TextareaProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
}

export const InputTextarea = React.forwardRef<null, IInputTextareaProps>(
	({ label, errorMsg, formControlProps, formLabelProps, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<FormLabel
						fontSize="14px"
						fontWeight={500}
						{...formLabelProps}>
						{label}
					</FormLabel>
				) : null}
				<Textarea
					border="1px solid"
					borderColor="gray.300"
					fontSize="14px"
					color="gray.900"
					_placeholder={{ color: 'gray.500' }}
					px={3}
					py={2}
					fontWeight={400}
					ref={ref}
					{...rest}
				/>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);

InputTextarea.displayName = 'InputTextarea';
