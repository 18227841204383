import React from 'react';
import {
	Box,
	BoxProps,
	Flex,
	FlexProps,
	IconButton,
	Text,
	TextProps,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';

interface ToastBodyProps {
	children: string;
	iconName?: string;
	colorScheme?: string;
	containerProps?: BoxProps;
	wrapperProps?: FlexProps;
	textProps?: TextProps;
	iconProps?: React.SVGProps<SVGSVGElement>;
	onClose?: VoidFunction;
}

export const ToastBody: React.FC<ToastBodyProps> = ({
	children,
	containerProps,
	wrapperProps,
	textProps,
	iconProps,
	iconName,
	onClose,
	colorScheme = 'green',
}) => {
	return (
		<Box
			zIndex={500}
			borderRadius="lg"
			minW="auto"
			w="max-content"
			maxW="400px"
			borderWidth="1px"
			p="16px"
			bg={`${colorScheme}.50`}
			borderColor={`${colorScheme}.200`}
			{...containerProps}>
			<Flex
				alignItems="flex-start"
				h="100%"
				justifyContent="space-between"
				columnGap="6px"
				{...wrapperProps}>
				{iconName ? (
					<Flex width="20px" height="24px" align="center">
						<Icon
							name={iconName}
							width="20px"
							height="20px"
							{...iconProps}
						/>
					</Flex>
				) : null}
				<Text
					variant="base"
					color={`${colorScheme}.800`}
					fontWeight={600}
					noOfLines={3}
					{...textProps}>
					{children}
				</Text>
				<Flex h="24px" align="center">
					<IconButton
						aria-label="close-alert"
						variant="ghost"
						width="20px"
						height="20px"
						borderRadius="md"
						_hover={{
							bg: { base: 'transparent', md: 'primary.100' },
						}}
						_active={{
							bg: { base: 'transparent', md: 'primary.200' },
						}}
						onClick={onClose}>
						<Icon
							name="close"
							width="14px"
							height="14px"
							color={colors.gray[900]}
						/>
					</IconButton>
				</Flex>
			</Flex>
		</Box>
	);
};
