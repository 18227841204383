import { IconButton, Tooltip, TooltipProps } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../../../theme/colors';
import { Icon } from '../../../components';

interface IconWithTooltipProps extends Partial<TooltipProps> {
	iconName: string;
	onClick: VoidFunction;
}

export const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
	iconName,
	onClick,
	...rest
}) => {
	return (
		<Tooltip
			borderRadius="base"
			boxShadow="md"
			p="6.5px 12px"
			fontSize="sm"
			bg="white"
			color="gray.600"
			fontWeight={500}
			hasArrow={true}
			{...rest}>
			<IconButton
				variant="unstyled"
				aria-label="edit-tool"
				w="20px"
				px="2px"
				minW="20px"
				h="20px"
				borderRadius="base"
				onClick={onClick}
				_hover={{
					bg: 'transparent',
				}}
				_active={{
					bg: 'transparent',
				}}>
				<Icon
					name={iconName}
					width="16px"
					height="16px"
					color={colors.gray[500]}
				/>
			</IconButton>
		</Tooltip>
	);
};
