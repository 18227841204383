import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, SelectOptionResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';

export const useCreateTag = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponse<number>, unknown, string>({
		mutationKey: [QueryKeys.CREATE_TAG],
		mutationFn: payload =>
			API.post('/api/admin/ai-tags', { name: payload }),
		onSuccess(data, variables) {
			if (data.success) {
				queryClient.setQueryData<ApiResponse<SelectOptionResponse[]>>(
					[QueryKeys.FETCH_TAGS],
					oldData => {
						if (oldData) {
							return {
								...oldData,
								value: [
									...(oldData?.value || []),
									{ id: data.value, name: variables },
								],
							};
						}
						return oldData;
					},
				);
				Alerter.success('Tag successfully created');
			} else {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Tag creation was not successful',
				);
			}
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while creating tag',
			);
		},
	});
};
