import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Text,
	Flex,
	Wrap,
	Tag,
	HStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { InputText, Loader } from '../../../components';
import { CreateTagForm, createTagResolver } from '../../../validation';
import { useFetchTags, useCreateTag, useDeleteTags } from '../queries';
import { SelectOptionResponse } from '../../../types';

interface IAddTagsModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	selectedItems?: SelectOptionResponse[];
	onSubmit: (data: SelectOptionResponse[]) => void;
}

export const AddTagsModal: React.FC<IAddTagsModalProps> = ({
	isOpen,
	onClose,
	selectedItems,
	onSubmit,
}) => {
	const [selectedTags, setSelectedTags] = useState<SelectOptionResponse[]>(
		[],
	);
	const {
		formState: { errors },
		handleSubmit,
		register,
		reset,
	} = useForm<CreateTagForm>({
		resolver: createTagResolver,
	});

	const deleteTagsMutation = useDeleteTags();

	useEffect(() => {
		if (isOpen && selectedItems?.length) {
			setSelectedTags(selectedItems);
		}
	}, [isOpen, selectedItems]);

	const { data: tagsData, isPending } = useFetchTags();
	const createTagMutation = useCreateTag();

	const onCreateTag = (data: CreateTagForm) => {
		createTagMutation.mutate(data.name, {
			onSuccess(data) {
				if (data.success) {
					reset({ name: '' });
				}
			},
		});
	};

	const onSelectTag = (tag: SelectOptionResponse) => {
		const alreadySelected = !!selectedTags.find(elem => elem.id === tag.id);
		if (alreadySelected) {
			setSelectedTags(prev => prev.filter(elem => elem.id !== tag.id));
		} else {
			setSelectedTags(prev => [...prev, tag]);
		}
	};

	const onPermanentlyDeleteTags = () => {
		deleteTagsMutation.mutate(
			selectedTags.map(elem => elem.id),
			{
				onSuccess(data, variables) {
					if (data.success) {
						onSubmit(
							selectedItems?.filter(
								elem => !variables.includes(elem.id),
							) || [],
						);
					}
				},
			},
		);
	};

	const onAddTags = () => {
		onSubmit(selectedTags);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} size="lg" onClose={onClose} isCentered={true}>
			<ModalOverlay bg="#1E429F80" />
			<ModalContent>
				<ModalHeader fontSize="md" px={5} py={3}>
					Add Tags
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody px={4}>
					<form onSubmit={handleSubmit(onCreateTag)}>
						<Flex gap={3}>
							<InputText
								placeholder="Add a new tag"
								{...register('name')}
								errorMsg={errors.name?.message}
							/>
							<Button
								variant="outline"
								type="submit"
								isLoading={createTagMutation.isPending}>
								Create Tag
							</Button>
						</Flex>
					</form>
					<Text variant="sm" fontWeight="500" mt={4}>
						Tags
					</Text>
					{isPending ? (
						<Loader spinnerSize="md" />
					) : (
						<>
							{!tagsData?.value?.length ? (
								<Text>No tags yet</Text>
							) : (
								<Wrap mt={3} spacing={2}>
									{tagsData?.value?.map(elem => {
										const isSelected = !!selectedTags.find(
											item => item.id === elem.id,
										);
										return (
											<Tag
												key={elem.id}
												bg={
													isSelected
														? 'primary.100'
														: 'gray.100'
												}
												as="button"
												onClick={() =>
													onSelectTag(elem)
												}
												color={
													isSelected
														? 'primary.800'
														: 'gray.900'
												}
												fontSize="12px">
												{elem.name}
											</Tag>
										);
									})}
								</Wrap>
							)}
						</>
					)}
				</ModalBody>

				<ModalFooter px={5} py={3}>
					<Button variant="outline-gray" onClick={onClose} mr="auto">
						Cancel
					</Button>
					<HStack spacing={4}>
						<Button
							variant="outline"
							colorScheme="red"
							isDisabled={!selectedTags.length}
							isLoading={deleteTagsMutation.isPending}
							onClick={onPermanentlyDeleteTags}>
							Permanently Remove tag(s)
						</Button>
						<Button
							onClick={onAddTags}
							isDisabled={!selectedTags.length}>
							Add tags
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
