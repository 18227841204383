import React from 'react';
import { Flex, Button, HStack } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import { InputText, Select, InputTextarea, Icon } from '../../../components';
import {
	WrapperWithPreviewBox,
	TagsInput,
	FollowIndexButton,
} from '../components';
import { CreateToolPayload } from '../../../types';
import { useFetchCategories } from '../../../queries';

interface IBasicInfoStepProps {
	handleTabsChange: (index: number) => void;
}

export const BasicInfoStep: React.FC<IBasicInfoStepProps> = ({
	handleTabsChange,
}) => {
	const { data: categoriesData } = useFetchCategories();

	const {
		control,
		formState: { errors },
		register,
		trigger,
		setValue,
		watch,
		clearErrors,
	} = useFormContext<CreateToolPayload>();

	const handleNext = async () => {
		const isStepValid = await trigger();
		if (isStepValid) {
			handleTabsChange(1);
		}
	};

	const tagsWatch = watch('tags');

	return (
		<>
			<WrapperWithPreviewBox>
				<InputText
					{...register('seoTitle')}
					errorMsg={errors.seoTitle?.message}
					label="SEO title"
					height="42px"
				/>
				<InputTextarea
					{...register('seoDescription')}
					errorMsg={errors.seoDescription?.message}
					label="SEO meta description"
				/>
				<InputTextarea
					{...register('seoSchema')}
					errorMsg={errors.seoSchema?.message}
					label="SEO schema"
				/>
				<Flex gap="16px">
					<InputText
						{...register('toolName')}
						errorMsg={errors.toolName?.message}
						label="Tool name"
					/>
					<Controller
						control={control}
						name="categoryId"
						render={({ field }) => (
							<Select
								label="Tool Category"
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value}
								errorMsg={errors.categoryId?.message}
								options={
									categoriesData?.value.map(elem => ({
										label: elem.name,
										value: elem.id.toString(),
									})) || []
								}
							/>
						)}
					/>
				</Flex>
				<InputText
					{...register('websiteUrl')}
					errorMsg={errors.websiteUrl?.message}
					label="Website URL"
				/>
				<Controller
					control={control}
					name="isDoFollowLink"
					render={({ field }) => (
						<HStack spacing={2}>
							<FollowIndexButton
								onClick={() => field.onChange(true)}
								isSelected={field.value}>
								DoFollow
							</FollowIndexButton>
							<FollowIndexButton
								onClick={() => field.onChange(false)}
								isSelected={!field.value}>
								NoFollow
							</FollowIndexButton>
						</HStack>
					)}></Controller>

				<InputText
					{...register('slug')}
					errorMsg={errors.slug?.message}
					label="Spicytools URL"
					leftAddon="https://spicytools.ai/tool/"
				/>
				<TagsInput
					setValue={setValue}
					tags={tagsWatch}
					errorMsg={errors?.tags?.message}
					clearErrors={clearErrors}
				/>
			</WrapperWithPreviewBox>
			<Flex
				px={6}
				py={4}
				borderTop="1px solid"
				borderTopColor="gray.200"
				mt="auto">
				<Button
					ml="auto"
					onClick={handleNext}
					rightIcon={
						<Icon
							name="arrow-right"
							width="20px"
							height="20px"
							color="white"
						/>
					}>
					Next
				</Button>
			</Flex>
		</>
	);
};
