import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';

interface IOptionButtonProps {
	iconName: string;
	onClick: VoidFunction;
	isActive?: boolean;
	isDisabled?: boolean;
	ariaLabel: string;
}

export const OptionButton: React.FC<IOptionButtonProps> = ({
	iconName,
	onClick,
	isActive,
	isDisabled,
	ariaLabel,
}) => {
	return (
		<IconButton
			aria-label={ariaLabel}
			variant="unstyled"
			onClick={onClick}
			display="flex"
			alignItems="center"
			justifyContent="center"
			boxSize="24px"
			borderRadius="4px"
			bg={isActive ? 'primary.300' : 'transparent'}
			border="none"
			size="xxs"
			isDisabled={isDisabled}>
			<Icon name={iconName} width="20px" height="20px" />
		</IconButton>
	);
};
