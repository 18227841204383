import React, { useState, useEffect } from 'react';
import { Container, Flex, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Steps } from '../../components';
import { BasicInfoStep, ToolDescriptionStep, ImagesStep } from './steps';
import { Header } from './components';
import { BlockerDialog } from './modals';
import { CreateToolPayload } from '../../types';
import { createToolSchemas } from '../../validation';
import { useFetchToolById } from './queries';
import { steps } from '../../constants';

export const AddToolPage: React.FC = () => {
	const [tabIndex, setTabIndex] = useState(0);

	const { id } = useParams();

	const isEditMode = !!id;

	const { data } = useFetchToolById(id);

	const currentValidationSchema = createToolSchemas[tabIndex];

	const methods = useForm<CreateToolPayload>({
		shouldUnregister: false,
		defaultValues: { isDoFollowLink: true },
		// @ts-ignore
		resolver: yupResolver(currentValidationSchema),
		mode: 'onChange',
	});

	useEffect(() => {
		if (data) {
			const val = data?.value;
			methods.reset({
				bodyText: val?.bodyText,
				bodyTextShort: val?.bodyTextShort,
				seoTitle: val?.seoTitle,
				seoDescription: val?.seoDescription,
				shortDescription: val?.shortDescription,
				seoSchema: val?.seoSchema || '',
				toolName: val?.name,
				websiteUrl: val?.websiteUrl,
				isFeatured: val?.isFeatured,
				slug: val?.slug,
				categoryId: {
					value: val?.category.id.toString(),
					label: val?.category.name,
				},
				tags: val?.tags,
				images: val?.images,
				thumbnail: val?.thumbnail || undefined,
				imageIdsToDelete: [],
				isThumbnailDeleted: false,
				isDoFollowLink: val?.isDoFollowLink,
			});
		}
	}, [data, methods, id]);

	const handleTabsChange = (index: number) => {
		setTabIndex(index);
	};

	return (
		<>
			<Flex flexDirection="column" height="100%">
				<Header isEditMode={isEditMode} />
				<Container maxW="container.xl">
					<Steps currentIndex={tabIndex} steps={steps} />
				</Container>
				<FormProvider {...methods}>
					<Tabs index={tabIndex}>
						<TabPanels>
							<TabPanel p={0}>
								<BasicInfoStep
									handleTabsChange={handleTabsChange}
								/>
							</TabPanel>
							<TabPanel p={0}>
								<ToolDescriptionStep
									handleTabsChange={handleTabsChange}
								/>
							</TabPanel>
							<TabPanel p={0}>
								<ImagesStep
									handleTabsChange={handleTabsChange}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</FormProvider>
			</Flex>
			<BlockerDialog isDirty={methods.formState.isDirty} />
		</>
	);
};
