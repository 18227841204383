import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Flex, Heading, Text, VStack } from '@chakra-ui/react';

interface ErrorBoundaryProps {}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = () => {
	const error = useRouteError();

	const showError = (error: unknown) => {
		switch (true) {
			case isRouteErrorResponse(error):
				return error.data;
			case error instanceof Error:
				return error.stack;
			default:
				return null;
		}
	};

	const pageError = showError(error);
	const isProduction = import.meta.env.VITE_MODE === 'production';

	return (
		<Flex h="100dvh" justify="center" align="center">
			<VStack spacing={3}>
				<Heading as="h1">Something went wrong</Heading>
				<Text>Reload the page or try again later.</Text>
				{pageError && !isProduction ? (
					<Text color="red.500" fontWeight={700}>
						{pageError}
					</Text>
				) : null}
			</VStack>
		</Flex>
	);
};
