import { SelectOptionResponse } from './global.types';

type ImageResponse = {
	id: number;
	imageUrl: string;
};

export enum ToolStatus {
	Unpublished = 1,
	Published = 2,
}

export type CreateToolPayload = {
	seoTitle: string;
	seoDescription: string;
	seoSchema: string;
	toolName: string;
	websiteUrl: string;
	slug: string;
	categoryId: {
		label: string;
		value: string;
	};
	shortDescription: string;
	bodyTextShort: string;
	bodyText: string;
	images: (File | ImageResponse)[];
	thumbnail: File | ImageResponse | null;
	isFeatured: boolean;
	tags: SelectOptionResponse[];
	imageIdsToDelete?: number[];
	isThumbnailDeleted?: boolean;
	isDoFollowLink: boolean;
};

export type ToolResponse = {
	id: number;
	name: string;
	websiteUrl: string;
	isFeatured: boolean;
	status: ToolStatus;
	shortDescription: string;
	bodyText: string;
	bodyTextShort: string;
	category: SelectOptionResponse;
	seoTitle: string;
	seoDescription: string;
	seoSchema: string;
	tags: SelectOptionResponse[];
	images: ImageResponse[];
	thumbnail: ImageResponse | null;
	slug: string;
	isDoFollowLink: boolean;
};
