import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDisclosure, Drawer, DrawerContent } from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const location = useLocation();

	useEffect(() => {
		onClose();
	}, [location.pathname, onClose]);

	return (
		<>
			<SidebarContent
				onClose={onClose}
				display={{ base: 'none', md: 'flex' }}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full">
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
		</>
	);
};
