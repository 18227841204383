import {
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BODY_TABS } from '../constants';
import { ToolDirectoryBodyTab } from '../tabs';
import { useFetchCategories } from '../../../queries';
import { toSelectOptionsFormat } from '../../../utils';

export const BodySection: React.FC = () => {
	const { data: categoriesData } = useFetchCategories();
	const [tabIndex, setTabIndex] = useState(0);
	return (
		<Tabs
			p="16px 24px"
			isLazy={true}
			variant="unstyled"
			overflow="auto"
			index={tabIndex}>
			<TabList>
				<TabList columnGap="20px">
					{BODY_TABS.map((tab, index) => (
						<Tab
							key={index}
							px="0"
							fontSize="sm"
							color="gray.500"
							fontWeight={600}
							_selected={{ color: 'primary.700' }}
							onClick={() => setTabIndex(index)}>
							{tab}
						</Tab>
					))}
				</TabList>
			</TabList>
			<TabIndicator mt="-1.5px" height="1px" bg="primary.700" />
			<TabPanels h="calc(100% - 40px)">
				<TabPanel p="0" overflow="auto" h="full">
					<ToolDirectoryBodyTab
						isDraft={tabIndex === 0}
						statusId={tabIndex + 1}
						categories={toSelectOptionsFormat(
							categoriesData?.value || [],
						)}
					/>
				</TabPanel>
				<TabPanel p="0" overflow="auto" h="full">
					<ToolDirectoryBodyTab
						isDraft={tabIndex === 0}
						statusId={tabIndex + 1}
						categories={toSelectOptionsFormat(
							categoriesData?.value || [],
						)}
					/>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};
