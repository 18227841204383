import { FC } from 'react';
import { TableColumnHeaderProps, Th } from '@chakra-ui/react';

interface RenderThProps extends TableColumnHeaderProps {
	value: string | number;
}

export const RenderTh: FC<RenderThProps> = ({ value, ...props }) => {
	return (
		<Th
			textTransform="capitalize"
			fontSize="16px"
			fontWeight={600}
			color="white"
			p="16px 12px"
			{...props}>
			{value}
		</Th>
	);
};
