import React from 'react';
import { colors } from '../../theme/colors';

interface OwnProps extends React.SVGProps<SVGSVGElement> {
	prefix?: string;
	strokeColor?: string;
	fillColor?: string;
}

export const Icon: React.FC<OwnProps> = ({
	name = '',
	prefix = 'icon',
	color = colors.gray[500],
	strokeColor = 'currentColor',
	fillColor = 'currentColor',
	...props
}) => {
	const symbolId = `#${prefix}-${name}`;

	return (
		<svg {...props} color={color} aria-hidden="true">
			<use
				href={symbolId}
				style={{ fill: fillColor, stroke: strokeColor }}
			/>
		</svg>
	);
};
