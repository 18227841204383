import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
	Flex,
	VStack,
	CloseButton,
	FlexProps,
	Text,
	Link,
	Box,
} from '@chakra-ui/react';
import { NavItem } from './NavItem';
import { navLinkItems } from '../../../constants';
import { Icon } from '../../Icon/Icon';
import { SidebarFooter } from './SidebarFooter';

interface SidebarContentProps extends FlexProps {
	onClose: () => void;
}

export const SidebarContent: React.FC<SidebarContentProps> = ({
	onClose,
	...rest
}) => {
	const location = useLocation();

	return (
		<Flex
			direction="column"
			align="stretch"
			bg="gray.50"
			borderRightWidth="1px"
			borderRightColor="gray.200"
			h="100vh"
			p="16px 16px 20px 16px"
			w={{ base: 'full', md: '256px' }}
			minW={{ base: 'full', md: '256px' }}
			overflowY="auto"
			{...rest}>
			<Link
				to="/"
				as={RouterLink}
				display={{ base: 'none', md: 'flex' }}
				w="fit-content"
				columnGap="8px"
				alignItems="center"
				_hover={{ bg: 'transparent' }}>
				<Icon
					name="logo"
					width="24px"
					height="24px"
					strokeColor="transparent"
				/>
				<Text variant="lg" color="gray.900" fontWeight={600}>
					Tools Directory
				</Text>
			</Link>
			<Flex
				display={{ base: 'flex', md: 'none' }}
				direction="column"
				alignItems="stretch"
				position="relative"
				mt="20px">
				<Flex
					mb={6}
					justifyContent="center"
					alignItems="center"
					mx={{ base: 0, md: 4 }}>
					<Link
						to="/"
						as={RouterLink}
						display={{ base: 'flex', md: 'none' }}
						w="fit-content"
						alignItems="center"
						columnGap="8px"
						_hover={{ bg: 'transparent' }}>
						<Icon
							name="logo"
							width="24px"
							height="24px"
							strokeColor="transparent"
						/>
						<Text variant="2xl" color="gray.900" fontWeight={600}>
							Tools Directory
						</Text>
					</Link>
					<CloseButton
						position="absolute"
						right="0px"
						top="2px"
						color="gray.900"
						display={{ base: 'flex', md: 'none' }}
						onClick={onClose}
					/>
				</Flex>
			</Flex>
			<VStack
				spacing={{ base: '24px', md: '12px' }}
				mt="36px"
				align={{ base: 'center', md: 'stretch' }}
				w={{ base: undefined, md: '100%' }}>
				{navLinkItems.map((elem, index) => (
					<NavItem
						key={index}
						name={elem.name}
						path={elem.path}
						iconName={elem.iconName}
						isActive={location.pathname === elem.path}
						isSoon={elem.isSoon}
					/>
				))}
			</VStack>

			<Box display={{ base: 'none', md: 'block' }} mt="auto">
				<SidebarFooter />
			</Box>
		</Flex>
	);
};
