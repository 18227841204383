import React from 'react';
import {
	Tag,
	HStack,
	Flex,
	TagCloseButton,
	IconButton,
	FormControl,
	FormErrorMessage,
	FormLabel,
	useDisclosure,
} from '@chakra-ui/react';
import { UseFormSetValue, UseFormClearErrors } from 'react-hook-form';
import { Icon } from '../../../components';
import { SelectOptionResponse, CreateToolPayload } from '../../../types';
import { AddTagsModal } from '../modals';
import { colors } from '../../../theme/colors';

interface ITagsInputProps {
	tags: SelectOptionResponse[];
	errorMsg?: string;
	setValue: UseFormSetValue<CreateToolPayload>;
	clearErrors: UseFormClearErrors<CreateToolPayload>;
}

export const TagsInput: React.FC<ITagsInputProps> = ({
	tags,
	errorMsg,
	setValue,
	clearErrors,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<FormControl isInvalid={!!errorMsg}>
				<FormLabel variant="base" fontWeight={500} mb={2}>
					Add tags
				</FormLabel>
				<HStack align="flex-start">
					<Flex
						borderRadius="8px"
						border="1px solid"
						px={3}
						py={2}
						w="100%"
						minH="42px"
						gap="8px"
						flexWrap="wrap"
						bg={errorMsg ? 'red.50' : 'white'}
						borderColor={errorMsg ? 'red.500' : 'gray.300'}>
						{tags?.map(elem => (
							<Tag
								key={elem.id}
								bg="gray.100"
								color="gray.900"
								fontSize="12px">
								{elem.name}
								<TagCloseButton
									onClick={() =>
										setValue(
											'tags',
											tags.filter(
												el => el.id !== elem.id,
											),
										)
									}
								/>
							</Tag>
						))}
					</Flex>
					<IconButton
						aria-label="Add tag"
						w="40px"
						variant="outline"
						flexShrink={0}
						onClick={onOpen}>
						<Icon
							name="plus"
							width="16px"
							height="16px"
							color={colors.primary[500]}
						/>
					</IconButton>
				</HStack>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
			<AddTagsModal
				isOpen={isOpen}
				onClose={onClose}
				onSubmit={tags => {
					setValue('tags', tags);
					clearErrors('tags');
				}}
				selectedItems={tags}
			/>
		</>
	);
};
