import React, { useCallback } from 'react';
import { Flex, Text, Switch, Wrap } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import {
	BottomActionsBar,
	WrapperWithPreviewBox,
	PreviewImage,
	UploadArea,
} from '../components';
import { useCreateTool, useUpdateTool } from '../queries';
import { CreateToolPayload, ToolStatus } from '../../../types';
import { constructCreateToolPayload, appendEditToolData } from '../../../utils';

interface IImagesStepProps {
	handleTabsChange: (index: number) => void;
}

export const ImagesStep: React.FC<IImagesStepProps> = ({
	handleTabsChange,
}) => {
	const createToolMutation = useCreateTool(ToolStatus.Published);
	const draftToolMutation = useCreateTool(ToolStatus.Unpublished);
	const updateToolMutation = useUpdateTool();
	const updateDraftToolMutation = useUpdateTool();

	const { id } = useParams();

	const {
		control,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
		reset,
		getValues,
		clearErrors,
	} = useFormContext<CreateToolPayload>();

	const imagesWatch = watch('images');
	const thumbnailWatch = watch('thumbnail');

	const onSubmit = (data: CreateToolPayload) => {
		const fd = new FormData();
		constructCreateToolPayload(fd, data);
		fd.append('Status', ToolStatus.Published.toString());
		reset(getValues());
		if (id) {
			appendEditToolData(fd, data);
			updateToolMutation.mutate({ id, data: fd });
		} else {
			createToolMutation.mutate(fd);
		}
	};

	const onDraftSave = (data: CreateToolPayload) => {
		const fd = new FormData();
		constructCreateToolPayload(fd, data);
		fd.append('Status', ToolStatus.Unpublished.toString());
		reset(getValues());
		if (id) {
			appendEditToolData(fd, data);
			updateDraftToolMutation.mutate({ id, data: fd });
		} else {
			draftToolMutation.mutate(fd);
		}
	};

	const onRemovePreview = (index: number) => {
		setValue(
			'images',
			imagesWatch.filter((_, i) => i !== index),
		);
		const uploadedImageToDelete = imagesWatch[index];
		if ('imageUrl' in uploadedImageToDelete) {
			setValue('imageIdsToDelete', [
				...(getValues('imageIdsToDelete') || []),
				uploadedImageToDelete.id,
			]);
		}
	};

	const onRemoveThumbnail = () => {
		setValue('thumbnail', null);
		setValue('isThumbnailDeleted', true);
	};

	const onUploadImages = useCallback(
		(files: File[]) => {
			setValue('images', [...(getValues('images') || []), ...files]);
			clearErrors('images');
		},
		[getValues, setValue],
	);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<WrapperWithPreviewBox>
				<Controller
					name="thumbnail"
					control={control}
					render={({ field }) => (
						<UploadArea
							onChange={files => field.onChange(files[0])}
							errorMsg={errors.thumbnail?.message}
							dropzoneOptions={{ multiple: false }}
							title="Upload a thumbnail image"
						/>
					)}
				/>
				{thumbnailWatch ? (
					<PreviewImage
						src={
							'imageUrl' in thumbnailWatch
								? `${import.meta.env.VITE_IMAGE_PUBLIC_BUCKET_URL}/${thumbnailWatch.imageUrl}`
								: URL.createObjectURL(thumbnailWatch)
						}
						onRemove={onRemoveThumbnail}
					/>
				) : null}

				<UploadArea
					onChange={onUploadImages}
					errorMsg={errors.images?.message}
					title="Upload a gallery image"
				/>

				{imagesWatch?.length ? (
					<Wrap gap={4} py={4}>
						{imagesWatch.map((elem, index) => (
							<PreviewImage
								key={index}
								src={
									'imageUrl' in elem
										? `${import.meta.env.VITE_IMAGE_PUBLIC_BUCKET_URL}/${elem.imageUrl}`
										: URL.createObjectURL(elem)
								}
								onRemove={() => onRemovePreview(index)}
							/>
						))}
					</Wrap>
				) : null}
				<Flex align="center" justify="space-between" mt={8}>
					<Text variant="base" fontWeight="400">
						Feature this tool
					</Text>
					<Controller
						control={control}
						name="isFeatured"
						render={({ field: { onChange, onBlur, value } }) => (
							<Switch
								onChange={e => onChange(e.target.checked)}
								onBlur={onBlur}
								colorScheme="primary"
								isChecked={value}
								size="lg"
							/>
						)}></Controller>
				</Flex>
			</WrapperWithPreviewBox>
			<BottomActionsBar
				onBack={() => handleTabsChange(1)}
				includeNextArrow={false}
				confirmLabel={id ? 'Save changes & publish' : 'Save & publish'}
				confirmType="submit"
				draftLoading={
					updateDraftToolMutation.isPending ||
					draftToolMutation.isPending
				}
				onDraftSave={() => handleSubmit(onDraftSave)()}
				confirmLoading={
					createToolMutation.isPending || updateToolMutation.isPending
				}
			/>
		</form>
	);
};
