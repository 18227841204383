import { ComponentStyleConfig } from '@chakra-ui/react';

export const Textarea: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		outline: {
			borderRadius: '8px',
			_focusVisible: {
				borderColor: 'primary.600',
			},
			_invalid: {
				borderColor: 'red.500',
				boxShadow: 'none',
				background: 'red.50',
				color: 'red.700',
			},
		},
	},
	defaultProps: {},
};
