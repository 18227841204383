import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, SelectOptionResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';

export const useDeleteTags = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponse<number>, unknown, number[]>({
		mutationKey: [QueryKeys.DELETE_TAGS],
		mutationFn: payload =>
			API.delete('/api/admin/ai-tags/', { tagIds: payload }),
		onSuccess(data, variables) {
			if (data.success) {
				queryClient.setQueryData<ApiResponse<SelectOptionResponse[]>>(
					[QueryKeys.FETCH_TAGS],
					oldData => {
						if (oldData) {
							return {
								...oldData,
								value: oldData.value.filter(
									elem => !variables.includes(elem.id),
								),
							};
						}
						return oldData;
					},
				);
				Alerter.success('Tags successfully deleted');
			} else {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Tags deletion was not successful',
				);
			}
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while deleting tags',
			);
		},
	});
};
