import React from 'react';
import { Flex, Text, Divider, Center } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';

interface IStepperProps {
	isCurrent: boolean;
	isCompleted: boolean;
	label: string;
	stepNumber: number;
}

export const Stepper: React.FC<IStepperProps> = ({
	label,
	stepNumber,
	isCompleted,
	isCurrent,
}) => {
	return (
		<>
			<Flex flexShrink={0} align="center" gap={1.5}>
				{isCompleted ? (
					<Icon
						name="badge-check"
						height="20px"
						width="20px"
						color={colors.primary[700]}
					/>
				) : (
					<Center
						boxSize="20px"
						borderRadius="50%"
						border="1px solid"
						borderColor={isCurrent ? 'primary.700' : 'gray.500'}>
						<Text
							variant="xs"
							fontWeight="500"
							color={isCurrent ? 'primary.700' : 'gray.500'}>
							{stepNumber}
						</Text>
					</Center>
				)}
				<Text
					variant="sm"
					fontWeight={500}
					color={
						isCurrent || isCompleted ? 'primary.700' : 'gray.500'
					}>
					{label}
				</Text>
			</Flex>
			<Divider
				_last={{ display: 'none' }}
				borderColor="gray.300"
				mx={4}
			/>
		</>
	);
};
