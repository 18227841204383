import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../../components';
import { colors } from '../../../theme/colors';

interface IHeaderProps {
	isEditMode: boolean;
}

export const Header: React.FC<IHeaderProps> = ({ isEditMode }) => {
	const navigate = useNavigate();
	return (
		<Box
			borderBottom="1px solid"
			borderBottomColor="gray.200"
			px={6}
			py={5}>
			<HStack spacing="12px" as="button" onClick={() => navigate(-1)}>
				<Icon
					name="back"
					width="14px"
					height="14px"
					color={colors.gray[900]}
				/>
				<Text variant="base" fontWeight={700}>
					{isEditMode ? 'Edit tool information' : 'Add Tool'}
				</Text>
			</HStack>
		</Box>
	);
};
