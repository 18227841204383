import axios, { AxiosRequestConfig } from 'axios';
import { ApiResponse } from '../types';
import { store } from '../store/createStore';
import { setAuthHeaderToken } from '../utils';
import intercept from './interceptor';

type KeyValue<U> = {
	[key: string]: U;
};

intercept();

const handleError = (err: any) => {
	if (err && err.response && err?.response?.data) {
		return { data: err.response.data };
	} else {
		return {
			data: {
				success: false,
				errors: [
					{
						key: 'Request error',
						message: 'Request error',
					},
				],
			},
		};
	}
};

class Api<T> {
	async get(url: string, params: any, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.get(url, {
				params,
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async post(
		url: string,
		body: T,
		config: AxiosRequestConfig = {},
	): Promise<ApiResponse<any>> {
		const response = await axios
			.post(url, body, config)
			.catch(err => handleError(err));

		return response.data;
	}

	async put(url: string, body: T, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.put(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async patch(url: string, body: T, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.patch(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async delete(url: string, body?: T, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.delete(url, {
				data: body,
				headers,
			})
			.catch(err => handleError(err));
		return response.data;
	}
}

export default new Api();
