import React from 'react';
import { MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';

interface UserMenuProps {
	onLogout: VoidFunction;
	logoutLoading: boolean;
}

export const UserMenu: React.FC<UserMenuProps> = ({
	onLogout,
	logoutLoading,
}) => {
	return (
		<MenuList
			w={{ base: '28px', md: 'auto' }}
			minW={{ base: 'auto', md: '3xs' }}
			py="0"
			borderRadius="lg"
			overflow="hidden">
			<MenuItem
				onClick={onLogout}
				h={{ base: '28px', md: '34px' }}
				py={{ base: '4px', md: '6px' }}
				px={{ base: '4px', md: '12px' }}
				bg="transparent"
				columnGap="8px"
				alignItems="center"
				_hover={{ bg: { base: 'transparent', md: 'primary.200' } }}>
				{logoutLoading ? (
					<Spinner w="20px" h="20px" />
				) : (
					<Icon
						name="logout"
						width="20px"
						height="20px"
						color={colors.gray[900]}
					/>
				)}
				<Text
					display={{ base: 'none', md: 'block' }}
					variant="base"
					fontWeight={600}
					color="gray.900">
					Log Out
				</Text>
			</MenuItem>
		</MenuList>
	);
};
