import React, { useCallback } from 'react';
import {
	Flex,
	Text,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Icon } from '../../../components';

interface IUploadAreaProps {
	onChange: (value: File[]) => void;
	errorMsg?: string;
	dropzoneOptions?: DropzoneOptions;
	title: string;
}

export const UploadArea: React.FC<IUploadAreaProps> = ({
	onChange,
	errorMsg,
	dropzoneOptions,
	title,
}) => {
	const onDrop = useCallback((acceptedFiles: File[]) => {
		onChange(acceptedFiles);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
		accept: {
			'image/png': ['.png'],
			'image/svg+xml': ['.svg'],
			'image/jpeg': ['.jpeg', '.jpg'],
			'image/gif': ['.gif'],
		},
		...dropzoneOptions,
	});
	return (
		<FormControl isInvalid={!!errorMsg}>
			<FormLabel fontSize="14px" fontWeight={500}>
				{title}
			</FormLabel>
			<Flex
				align="center"
				flexDirection="column"
				justify="center"
				bg={isDragActive ? 'gray.200' : 'gray.50'}
				border="2px dashed"
				borderColor="gray.200"
				gap={2}
				py="75px"
				borderRadius="8px"
				{...getRootProps()}>
				<Icon name="upload" height="25px" width="25px" />
				<Text color="gray.500" variant="sm" fontWeight="400">
					<chakra.span fontWeight="600">Click to upload</chakra.span>{' '}
					or drag and drop
				</Text>
				<Text variant="xs" fontWeight="400" color="gray.500">
					SVG, PNG, JPG or GIF
				</Text>
				<input {...getInputProps()} />
			</Flex>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	);
};
