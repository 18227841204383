import { useQuery } from '@tanstack/react-query';
import { ApiResponse, SelectOptionResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';

export const useFetchTags = () => {
	return useQuery<ApiResponse<SelectOptionResponse[]>>({
		queryKey: [QueryKeys.FETCH_TAGS],
		queryFn: () => API.get('/api/admin/ai-tags', {}),
	});
};
