export enum QueryKeys {
	FETCH_CATEGORIES = 'fetch-categories',
	GET_TOOLS = 'get-tools',
	FETCH_TAGS = 'fetch-tags',
	FETCH_TOOL_BY_ID = 'fetch-tool-by-id',
	// mutation keys below
	LOGIN = 'login',
	LOGOUT = 'logout',
	CREATE_TOOL = 'create-tool',
	UPDATE_PUBLISH_STATUS = 'update-publish-status',
	DELETE_TOOL = 'delete-tool',
	CREATE_TAG = 'create-tag',
	DELETE_TAGS = 'delete-tags',
	UPDATE_TOOL = 'update-tool',
}
