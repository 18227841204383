import React from 'react';
import { Flex, Button, HStack } from '@chakra-ui/react';
import { Icon } from '../../../components';

interface IBottomActionsBarProps {
	onBack: VoidFunction;
	includeNextArrow?: boolean;
	confirmLabel: string;
	onConfirm?: VoidFunction;
	confirmType?: 'button' | 'submit' | 'reset';
	confirmLoading?: boolean;
	draftLoading?: boolean;
	onDraftSave?: VoidFunction;
}

export const BottomActionsBar: React.FC<IBottomActionsBarProps> = ({
	onBack,
	includeNextArrow,
	confirmLabel,
	onConfirm,
	confirmType = 'button',
	confirmLoading,
	draftLoading,
	onDraftSave,
}) => {
	return (
		<Flex
			px={6}
			py={4}
			borderTop="1px solid"
			borderTopColor="gray.200"
			mt="auto"
			align="center"
			justify="space-between">
			<Button variant="outline-gray" onClick={onBack}>
				Back
			</Button>
			<HStack spacing={4}>
				<Button
					variant="outline"
					isLoading={draftLoading}
					isDisabled={confirmLoading}
					onClick={onDraftSave}>
					Save to drafts
				</Button>
				<Button
					ml="auto"
					onClick={onConfirm}
					type={confirmType}
					isLoading={confirmLoading}
					isDisabled={draftLoading}
					rightIcon={
						includeNextArrow ? (
							<Icon
								name="arrow-right"
								width="20px"
								height="20px"
								color="white"
							/>
						) : undefined
					}>
					{confirmLabel}
				</Button>
			</HStack>
		</Flex>
	);
};
