import React from 'react';
import { Grid } from '@chakra-ui/react';
import { BodySection, HeaderSection } from './sections';

export const ToolsDirectoryPage: React.FC = () => {
	return (
		<Grid templateRows="auto 1fr" h="100dvh">
			<HeaderSection />
			<BodySection />
		</Grid>
	);
};
