import React, { PropsWithChildren } from 'react';
import { Flex, Box, Container } from '@chakra-ui/react';

export const WrapperWithPreviewBox: React.FC<PropsWithChildren> = ({
	children,
}) => {
	return (
		<Box height="calc(100vh - 185px)" pb="20px" overflowY="auto">
			<Container maxW="container.xl">
				<Flex gap={8} pt={5}>
					<Flex flexDirection="column" gap={4} flex={0.65}>
						{children}
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
};
