import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store/createStore';

export const ProtectedRoute: React.FC = () => {
	const location = useLocation();
	const { token, user } = useSelector((state: RootStore) => state.auth);
	const { accessToken } = token;
	const { roles } = user;

	if (!accessToken || !roles.includes('Admin')) {
		return (
			<Navigate to="/login" state={{ from: location }} replace={true} />
		);
	}

	return <Outlet />;
};
