import React from 'react';
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	SystemStyleObject,
	FormControlProps,
} from '@chakra-ui/react';
import {
	Select as ChakraSelect,
	chakraComponents,
	Props,
} from 'chakra-react-select';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';

interface ISelectProps extends Props {
	label?: string;
	errorMsg?: string;
	options: { label: string; value: string }[];
	containerProps?: SystemStyleObject;
	menuListProps?: SystemStyleObject;
	valueContainerProps?: SystemStyleObject;
	placeholderProps?: SystemStyleObject;
	optionProps?: SystemStyleObject;
	controlProps?: SystemStyleObject;
	dropdownIndicatorProps?: SystemStyleObject;
	formControlProps?: FormControlProps;
}

export const Select: React.FC<ISelectProps> = ({
	options,
	label,
	errorMsg,
	containerProps,
	menuListProps,
	valueContainerProps,
	placeholderProps,
	optionProps,
	controlProps,
	dropdownIndicatorProps,
	formControlProps,
	...rest
}) => {
	return (
		<FormControl isInvalid={!!errorMsg} {...formControlProps}>
			{label ? (
				<FormLabel fontSize="14px" fontWeight={500}>
					{label}
				</FormLabel>
			) : null}
			<ChakraSelect
				options={options}
				isSearchable={false}
				useBasicStyles={true}
				selectedOptionColorScheme="primary"
				components={{
					ClearIndicator: props => (
						<chakraComponents.ClearIndicator {...props}>
							<Icon
								name="close"
								width="11px"
								height="11px"
								color={colors.gray[600]}
							/>
						</chakraComponents.ClearIndicator>
					),
				}}
				chakraStyles={{
					container: provided => ({
						...provided,
						borderRadius: 'lg',
						cursor: 'pointer',
						...containerProps,
					}),
					dropdownIndicator: provided => ({
						...provided,
						color: 'gray.600',
						...dropdownIndicatorProps,
					}),
					control: provided => ({
						...provided,
						borderColor: 'gray.300',
						borderRadius: 'lg',
						...controlProps,
					}),
					menuList: provided => ({
						...provided,
						px: 3,
						py: 2,
						borderWidth: '1px',
						borderColor: 'gray.200',
						borderRadius: 'lg',
						boxShadow: 'lg',
						scrollbarWidth: 'thin',
						...menuListProps,
					}),
					valueContainer: provided => ({
						...provided,
						fontSize: '14px',
						color: 'gray.900',
						fontWeight: 400,
						px: 3,
						...valueContainerProps,
					}),
					placeholder: provided => ({
						...provided,
						color: 'gray.500',
						fontSize: '14px',
						fontWeight: 400,
						...placeholderProps,
					}),
					option: provided => ({
						...provided,
						borderRadius: '6px',
						fontSize: '14px',
						px: 2,
						py: 1.5,
						color: 'gray.900',
						fontWeight: 400,
						...optionProps,
					}),
					clearIndicator: provided => ({
						...provided,
						color: 'gray.600',
						width: '16px',
						height: '16px',
						fontSize: '9px',
						borderRadius: 'sm',
					}),
				}}
				{...rest}
			/>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	);
};
