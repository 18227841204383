import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
	baseStyle: {
		color: 'gray.900',
		lineHeight: '150%',
	},
	variants: {
		xs: {
			fontSize: '12px',
		},
		sm: {
			fontSize: '14px',
		},
		base: {
			fontSize: '16px',
		},
		lg: {
			fontSize: '18px',
		},
		xl: {
			fontSize: '20px',
		},
		'2xl': {
			fontSize: '24px',
			// letterSpacing: "-2%"
		},
		'3xl': {
			fontSize: '30px',
			// letterSpacing: "-2%"
		},
		'4xl': {
			fontSize: '36px',
			// letterSpacing: "-2%"
		},
		'uppercase-sm': {
			fontSize: '12px',
			textTransform: 'uppercase',
		},
		'uppercase-lg': {
			fontSize: '14px',
			textTransform: 'uppercase',
		},
	},
};
