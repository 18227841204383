import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Stepper } from './Stepper';

interface IStepsProps {
	steps: string[];
	currentIndex: number;
}

export const Steps: React.FC<IStepsProps> = ({ steps, currentIndex }) => {
	return (
		<Flex align="center" py={3}>
			{steps.map((elem, index) => {
				const isCompleted = index < currentIndex;
				const isCurrent = index === currentIndex;
				return (
					<Stepper
						key={index}
						isCurrent={isCurrent}
						isCompleted={isCompleted}
						label={elem}
						stepNumber={index + 1}
					/>
				);
			})}
		</Flex>
	);
};
