import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { ApiResponse, LoginResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import { setAuthStatus } from '../../../store/reducers/auth.reducer';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return useMutation<
		ApiResponse<LoginResponse>,
		unknown,
		{ login: string; password: string }
	>({
		mutationKey: [QueryKeys.LOGIN],
		mutationFn: payload => API.post('/api/auth/sign-in', payload),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error(
					data?.errors?.[0]?.message || 'Invalid login attempt',
				);
				return;
			}
			dispatch(setAuthStatus(data.value));
			navigate('/');
		},
	});
};
