import { ButtonProps, ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from '../colors';

export const Button: ComponentStyleConfig = {
	baseStyle: {
		color: 'white',
		lineHeight: '150%',
		borderRadius: 'lg',
		fontWeight: 500,
		transition: 'all 0.3s ease',
	},

	variants: {
		solid: (props: ButtonProps) => {
			const { colorScheme } = props;
			return {
				bg: `${colorScheme}.700`,
				_hover: {
					background: `${colorScheme}.800`,
					_disabled: {
						background: 'gray.200',
						color: 'gray.400',
					},
				},
				_focusVisible: {
					boxShadow: `0 0 0 3px var(--chakra-colors-${colorScheme}-200)`,
				},
				_active: {
					background: `${colorScheme}.900`,
				},
				_disabled: {
					background: 'gray.200',
					color: 'gray.400',
				},
			};
		},
		outline: (props: ButtonProps) => {
			const { colorScheme } = props;
			return {
				bg: 'transparent',
				borderWidth: '1px',
				borderColor: `${colorScheme}.700`,
				color: `${colorScheme}.700`,
				_hover: {
					background: `${colorScheme}.800`,
					borderColor: `${colorScheme}.800`,
					color: 'white',
					_disabled: {
						background: 'transparent',
						borderColor: 'gray.400',
						color: 'gray.400',
					},
				},
				_focusVisible: {
					boxShadow: `0 0 0 3px var(--chakra-colors-${colorScheme}-200)`,
				},
				_active: {
					background: `${colorScheme}.900`,
					borderColor: `${colorScheme}.900`,
					color: 'white',
				},
				_disabled: {
					background: 'transparent',
					borderColor: 'gray.400',
					color: 'gray.400',
				},
			};
		},
		['outline-gray']: {
			bg: 'white',
			borderWidth: '1px',
			borderColor: 'gray.200',
			color: 'gray.900',
			_hover: {
				background: 'gray.100',
				color: 'primary.700',
				_disabled: {
					background: 'transparent',
					borderColor: 'gray.400',
					color: 'gray.400',
				},
			},
			_focusVisible: {
				bg: 'white',
				color: 'primary.700',
				boxShadow: `0 0 0 3px ${colors.gray[100]}`,
			},
			_active: {
				background: 'gray.100',
				color: 'primary.700',
			},
			_disabled: {
				background: 'transparent',
				borderColor: 'gray.400',
				color: 'gray.400',
			},
		},
	},

	sizes: {
		xxs: {
			height: '26px',
			px: '12px',
			fontSize: 'xs',
		},
		xs: {
			height: '34px',
			px: '12px',
			fontSize: 'xs',
		},
		sm: {
			height: '37px',
			px: '12px',
			fontSize: 'sm',
		},
		base: {
			height: '41px',
			px: '20px',
			fontSize: 'sm',
		},
		lg: {
			height: '48px',
			px: '20px',
			fontSize: 'base',
		},
		xl: {
			height: '52px',
			px: '24px',
			fontSize: 'base',
		},
	},
	defaultProps: {
		size: 'base',
		variant: 'solid',
		colorScheme: 'primary',
	},
};
