import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import { OptionButton } from './OptionButton';

export const MenuBar: React.FC<{ editor: Editor | null }> = ({ editor }) => {
	if (!editor) {
		return null;
	}

	return (
		<Flex bg="gray.50" gap={2} px={4} py={3} borderTopRadius="8px">
			<OptionButton
				iconName="rte-h1"
				ariaLabel="H1"
				onClick={() =>
					editor.chain().focus().toggleHeading({ level: 1 }).run()
				}
				isActive={editor.isActive('heading', { level: 1 })}
			/>
			<OptionButton
				ariaLabel="H2"
				iconName="rte-h2"
				onClick={() =>
					editor.chain().focus().toggleHeading({ level: 2 }).run()
				}
				isActive={editor.isActive('heading', { level: 2 })}
			/>
			<OptionButton
				ariaLabel="H3"
				iconName="rte-h3"
				onClick={() =>
					editor.chain().focus().toggleHeading({ level: 3 }).run()
				}
				isActive={editor.isActive('heading', { level: 3 })}
			/>
			<OptionButton
				ariaLabel="Bold"
				iconName="rte-bold"
				onClick={() => editor.chain().focus().toggleBold().run()}
				isActive={editor.isActive('bold')}
				isDisabled={!editor.can().chain().focus().toggleBold().run()}
			/>
			<OptionButton
				ariaLabel="Italic"
				iconName="rte-italic"
				onClick={() => editor.chain().focus().toggleItalic().run()}
				isDisabled={!editor.can().chain().focus().toggleItalic().run()}
				isActive={editor.isActive('italic')}
			/>
			<OptionButton
				ariaLabel="Underline"
				iconName="rte-underline"
				onClick={() => editor.chain().focus().toggleUnderline().run()}
				isActive={editor.isActive('underline')}
			/>
			<OptionButton
				ariaLabel="Ordered list"
				iconName="rte-ordered-list"
				onClick={() => editor.chain().focus().toggleOrderedList().run()}
				isActive={editor.isActive('orderedList')}
			/>
			<OptionButton
				ariaLabel="Bullet list"
				iconName="rte-unordered-list"
				onClick={() => editor.chain().focus().toggleBulletList().run()}
				isActive={editor.isActive('bulletList')}
			/>
			<OptionButton
				ariaLabel="Align left"
				iconName="rte-text-align-left"
				onClick={() =>
					editor.chain().focus().setTextAlign('left').run()
				}
				isActive={editor.isActive({ textAlign: 'left' })}
			/>
			<OptionButton
				ariaLabel="Align right"
				iconName="rte-text-align-right"
				onClick={() =>
					editor.chain().focus().setTextAlign('right').run()
				}
				isActive={editor.isActive({ textAlign: 'right' })}
			/>
			<OptionButton
				ariaLabel="Justify"
				iconName="rte-text-align-justified"
				onClick={() =>
					editor.chain().focus().setTextAlign('justify').run()
				}
				isActive={editor.isActive({ textAlign: 'justify' })}
			/>
			<OptionButton
				ariaLabel="Center"
				iconName="rte-text-align-center"
				onClick={() =>
					editor.chain().focus().setTextAlign('center').run()
				}
				isActive={editor.isActive({ textAlign: 'center' })}
			/>
		</Flex>
	);
};
