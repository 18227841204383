import { createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary, ProtectedRoute } from '../components';
import { MainLayout } from '../components/MainLayout/MainLayout';
import {
	DashboardPage,
	AddToolPage,
	LoginPage,
	ToolsDirectoryPage,
} from '../pages';

export const router = createBrowserRouter([
	{
		element: <ProtectedRoute />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: '/',
				element: <MainLayout />,
				errorElement: <ErrorBoundary />,
				children: [
					{
						index: true,
						// element: <DashboardPage />,
						element: <ToolsDirectoryPage />,
						errorElement: <ErrorBoundary />,
					},
					// {
					// 	path: '/tools-directory',
					// 	element: <ToolsDirectoryPage />,
					// 	errorElement: <ErrorBoundary />,
					// },
					{
						path: '/add-tool',
						element: <AddToolPage />,
						errorElement: <ErrorBoundary />,
					},
					{
						path: '/tool/:id/edit',
						element: <AddToolPage />,
						errorElement: <ErrorBoundary />,
					},
				],
			},
		],
	},
	{
		path: '/login',
		element: <LoginPage />,
		errorElement: <ErrorBoundary />,
	},
]);
