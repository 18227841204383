import React, { useState } from 'react';
import {
	Input,
	FormControl,
	FormLabel,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	FormErrorMessage,
	InputProps,
	FormControlProps,
	FormLabelProps,
	InputLeftElementProps,
	IconButton,
	InputRightElementProps,
	InputLeftAddon,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';

interface IInputProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	onClickRightIcon?: () => void;
	leftIconProps?: InputLeftElementProps;
	isPassword?: boolean;
	rightIconProps?: InputRightElementProps;
	leftAddon?: string;
}

export const InputText = React.forwardRef<null, IInputProps>(
	(
		{
			errorMsg,
			label,
			leftIcon,
			rightIcon,
			formControlProps,
			formLabelProps,
			onClickRightIcon,
			leftIconProps,
			isPassword,
			rightIconProps,
			leftAddon,
			...rest
		},
		ref,
	) => {
		const [showPassword, setShowPassword] = useState(false);
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<FormLabel
						fontSize="14px"
						fontWeight={500}
						{...formLabelProps}>
						{label}
					</FormLabel>
				) : null}
				<InputGroup>
					{leftAddon ? (
						<InputLeftAddon
							fontSize="14px"
							borderTopColor="gray.300"
							borderLeftColor="gray.300"
							borderBottomColor="gray.300">
							{leftAddon}
						</InputLeftAddon>
					) : null}
					{leftIcon ? (
						<InputLeftElement h="100%" {...leftIconProps}>
							{leftIcon}
						</InputLeftElement>
					) : null}
					<Input
						type={
							isPassword
								? showPassword
									? 'text'
									: 'password'
								: rest.type
						}
						border="1px solid"
						borderColor="gray.300"
						fontSize="14px"
						color={
							isPassword && !showPassword
								? 'gray.500'
								: 'gray.900'
						}
						_placeholder={{ color: 'gray.500' }}
						pl={3}
						py={2}
						pr={isPassword ? '34px' : 3}
						fontWeight={400}
						{...rest}
						ref={ref}
					/>
					{rightIcon ? (
						<InputRightElement
							onClick={onClickRightIcon}
							h={'100%'}
							{...rightIconProps}>
							{rightIcon}
						</InputRightElement>
					) : null}
					{isPassword ? (
						<IconButton
							variant="unstyled"
							aria-label="hide/show password"
							position="absolute"
							right="12px"
							h="100%"
							w="21px"
							minW="21px"
							_focusVisible={{}}
							onClick={() => setShowPassword(prev => !prev)}
							zIndex={10}
							sx={{
								svg: {
									mx: 'auto',
								},
							}}>
							<Icon
								name={showPassword ? 'eye-off' : 'eye'}
								width="16px"
								height="16px"
								color={colors.gray[600]}
							/>
						</IconButton>
					) : null}
				</InputGroup>
				<FormErrorMessage>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
