import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		outline: {
			field: {
				borderRadius: '8px',
				borderColor: '#D1D5DB',
				_focusVisible: {
					borderColor: 'primary.600',
				},
				_invalid: {
					borderColor: 'red.500',
					boxShadow: 'none',
					background: 'red.50',
					color: 'red.700',
				},
			},
		},
	},
	defaultProps: {},
};
