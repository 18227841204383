import React, { useState } from 'react';
import {
	Box,
	Flex,
	Image,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Icon, InputText, Pagination, Select } from '../../../components';
import { colors } from '../../../theme/colors';
import { RemoveModal } from '../../../modals';
import { ToolDirectoryTable } from '../components';
import { useDeleteTool, useGetTools } from '../queries';
import { useDebounce } from '../../../hooks';
import { SelectOption, ToolStatus } from '../../../types';
import { useUpdatePublishStatus } from '../../../queries';
import { TOOLS_DIRECTORY_TABLE_HEADERS } from '../constants';

interface ToolDirectoryBodyTabProps {
	isDraft: boolean;
	statusId: number;
	categories: SelectOption[];
}

export const ToolDirectoryBodyTab: React.FC<ToolDirectoryBodyTabProps> = ({
	isDraft,
	statusId,
	categories,
}) => {
	const navigate = useNavigate();
	const {
		mutateAsync: updatePublishStatus,
		isPending: updatePublishStatusLoading,
	} = useUpdatePublishStatus();
	const { mutateAsync: deleteTool, isPending: deleteToolLoading } =
		useDeleteTool();

	const {
		isOpen: isRemoveModalOpen,
		onClose: onCloseRemoveModal,
		onOpen: onOpenRemoveModal,
	} = useDisclosure();

	const [term, setTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(12);
	const [categoryId, setCategoryId] = useState(0);
	const [selectedToolId, setSelectedToolId] = useState(0);

	const debouncedTerm = useDebounce(term, 500);

	const {
		data,
		isLoading,
		refetch: refetchTools,
	} = useGetTools(
		debouncedTerm,
		statusId,
		categoryId,
		pageSize * (currentPage - 1),
		pageSize,
	);

	const handleCloseRemoveModal = () => {
		onCloseRemoveModal();
		setSelectedToolId(0);
	};

	const onSuccessTableUpdate = () => {
		if (currentPage > 1 && data?.value?.tools?.length === 1) {
			setCurrentPage(prev => prev - 1);
			handleCloseRemoveModal();
			return;
		}

		refetchTools();
		handleCloseRemoveModal();
	};

	const onUpdatePublishStatus = async (id: number, status: ToolStatus) => {
		try {
			const res = await updatePublishStatus({ id, status });

			if (!res.status) {
				return;
			}
			onSuccessTableUpdate();
		} catch (error) {
			console.log('onUpdatePublishStatus error: ', error);
		}
	};

	const onDeleteTool = async (id: number) => {
		try {
			const res = await deleteTool(id);

			if (!res.status) {
				return;
			}

			onSuccessTableUpdate();
		} catch (error) {
			console.log('onUpdatePublishStatus error: ', error);
		}
	};

	return (
		<>
			<VStack spacing="16px" align="flex-start" mt="16px">
				<Flex columnGap="12px" w="100%" justifyContent="flex-start">
					<InputText
						value={term}
						onChange={e => setTerm(e.target.value)}
						w="100%"
						maxW={{ base: 'none', md: '240px' }}
						h="37px"
						placeholder="Search"
						pl="34px"
						leftIcon={
							<Icon
								name="search"
								width="16px"
								height="16px"
								color={colors.gray[500]}
							/>
						}
						leftIconProps={{
							w: '28px',
							minW: '28px',
							h: '37px',
							pl: '12px',
						}}
						rightIcon={
							term ? (
								<Icon
									name="close"
									width="11px"
									height="11px"
									color={colors.gray[600]}
								/>
							) : undefined
						}
						onClickRightIcon={() => setTerm('')}
						rightIconProps={{
							w: '16px',
							minW: '16px',
							h: '16px',
							mt: '10px',
							mr: '10px',
							borderRadius: 'sm',
							transition: 'background 0.3s ease',
							cursor: 'pointer',
							_hover: { bg: 'blackAlpha.100' },
						}}
						formControlProps={{
							w: '100%',
							maxW: { base: 'none', md: '240px' },
							pl: '1px',
						}}
					/>
					<Select
						isClearable={true}
						placeholder="Tool category"
						onChange={option => {
							if (!option) {
								setCategoryId(0);
								return;
							}
							setCategoryId(+(option as SelectOption).value);
						}}
						options={categories}
						containerProps={{
							maxW: { base: '100%', md: '240px' },
						}}
						controlProps={{
							h: '37px',
							minH: '37px',
						}}
						formControlProps={{
							maxW: { base: '100%', md: '240px' },
						}}
					/>
				</Flex>

				{!isLoading && !data?.value.totalCount ? (
					<Flex
						w="full"
						h="calc(100dvh - 210px)"
						justify="center"
						align="center">
						<VStack spacing={5}>
							<Image
								w="100px"
								minW="100px"
								h="100px"
								src="/blank-sheets.png"
							/>
							<Text variant="lg" fontWeight={700}>
								It is empty here
							</Text>
						</VStack>
					</Flex>
				) : (
					<Box pb="30px" w="100%">
						<ToolDirectoryTable
							headers={TOOLS_DIRECTORY_TABLE_HEADERS}
							isDraft={isDraft}
							selectToolId={(id: number) => setSelectedToolId(id)}
							navigateOnEdit={(id: number) =>
								navigate(`/tool/${id}/edit`)
							}
							isLoading={isLoading}
							data={data?.value?.tools || []}
							onPublishTool={id =>
								onUpdatePublishStatus(id, ToolStatus.Published)
							}
							selectedToolId={selectedToolId}
							publishLoading={updatePublishStatusLoading}
							onOpenRemoveModal={onOpenRemoveModal}
						/>
						<Box
							borderBottomRadius="lg"
							borderWidth="1px"
							borderTopWidth="0px"
							borderColor="gray.200">
							<Pagination
								currentPage={currentPage}
								setCurrentPage={val => setCurrentPage(val)}
								pageSize={pageSize}
								setPageSize={val => {
									setPageSize(val);
									setCurrentPage(1);
								}}
								totalCount={data?.value?.totalCount || 0}
								defaultPaginationProps={{
									mx: '0px',
									my: '0px',
									p: '10px 16px',
								}}
							/>
						</Box>
					</Box>
				)}
			</VStack>
			<RemoveModal
				isOpen={isRemoveModalOpen}
				onClose={handleCloseRemoveModal}
				removeButtonText="Delete"
				bodyMessage={
					isDraft
						? 'Are you sure you want to delete this draft?'
						: 'You can either choose to unpublish this tool or delete it. Unpublishing will move it to drafts.'
				}
				cancelButtonProps={{ w: isDraft ? '120px' : 'auto' }}
				removeButtonProps={{
					w: isDraft ? '120px' : 'auto',
					variant: isDraft ? 'solid' : 'outline',
					isLoading: deleteToolLoading,
					onClick: () => onDeleteTool(selectedToolId),
				}}
				includeAlternativeAction={!isDraft}
				alternativeButtonText="Unpublish"
				alternativeButtonProps={{
					w: 'auto',
					variant: 'solid',
					isLoading: updatePublishStatusLoading,
					onClick: () => {
						if (!selectedToolId) {
							return;
						}

						onUpdatePublishStatus(
							selectedToolId,
							ToolStatus.Unpublished,
						);
					},
				}}
				modalFooterProps={{
					justifyContent: isDraft ? 'center' : 'space-between',
				}}
			/>
		</>
	);
};
