import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { QueryKeys } from '../constants';
import { useSelector } from 'react-redux';
import { RootStore } from '../store/createStore';
import { ApiResponse, SelectOptionResponse } from '../types';

export const useFetchCategories = () => {
	const { accessToken } = useSelector((state: RootStore) => state.auth.token);
	return useQuery<ApiResponse<SelectOptionResponse[]>>({
		queryKey: [QueryKeys.FETCH_CATEGORIES],
		queryFn: () => API.get('/api/admin/ai-categories', {}),
		refetchOnWindowFocus: false,
		enabled: !!accessToken,
	});
};
