import { CreateToolPayload } from '../types';

export function constructCreateToolPayload(
	fd: FormData,
	data: CreateToolPayload,
) {
	fd.append('SeoTitle', data.seoTitle);
	fd.append('SeoDescription', data.seoDescription || '');
	if (data.seoSchema) {
		fd.append('SeoSchema', data.seoSchema);
	}
	fd.append('ToolName', data.toolName);
	fd.append('WebsiteUrl', data.websiteUrl);
	fd.append('Slug', data.slug);
	fd.append('ShortDescription', data.shortDescription);
	fd.append('BodyTextShort', data.bodyTextShort);
	fd.append('BodyText', data.bodyText);
	fd.append('CategoryId', data.categoryId.value);
	fd.append('IsFeatured', (!!data.isFeatured).toString());
	fd.append('IsDoFollowLink', data.isDoFollowLink.toString());
	if (data?.tags) {
		for (let i = 0; i < data.tags.length; i++) {
			fd.append('TagIds', data.tags[i].id.toString());
		}
	}
	if (data?.images) {
		for (let i = 0; i < data.images.length; i++) {
			if (data.images[i] instanceof File) {
				fd.append('Images', data.images[i] as File);
			}
		}
	}
	if (data?.thumbnail instanceof File) {
		fd.append('Thumbnail', data.thumbnail);
	}
}

export function appendEditToolData(fd: FormData, data: CreateToolPayload) {
	fd.append(
		'IsThumbnailDeleted',
		(data?.isThumbnailDeleted || false).toString(),
	);
	if (data?.imageIdsToDelete && data.imageIdsToDelete.length) {
		for (let i = 0; i < data.imageIdsToDelete.length; i++) {
			fd.append('ImageIdsToDelete', data.imageIdsToDelete[i].toString());
		}
	}
}
