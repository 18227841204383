import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const createTagSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
});

export type CreateTagForm = yup.InferType<typeof createTagSchema>;
export const createTagResolver = yupResolver(createTagSchema);
