import React from 'react';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { Icon } from '../../../components';
import { useNavigate } from 'react-router-dom';

interface HeaderSectionProps {}

export const HeaderSection: React.FC<HeaderSectionProps> = () => {
	const navigate = useNavigate();
	return (
		<Flex
			py="10.5px"
			px="24px"
			columnGap="8px"
			w="100%"
			justify="space-between"
			borderBottomWidth="1px"
			borderBottomColor="gray.200">
			<VStack align="flex-start" spacing={0}>
				<Text variant="base" fontWeight={700}>
					Tool Directory
				</Text>
				<Text variant="xs" color="gray.400">
					You can view your web tools list here.
				</Text>
			</VStack>
			<Button
				w="100%"
				maxW="130px"
				iconSpacing="6px"
				leftIcon={
					<Icon
						name="plus"
						width="16px"
						height="16px"
						color="white"
					/>
				}
				onClick={() => navigate('/add-tool')}>
				Add tool
			</Button>
		</Flex>
	);
};
