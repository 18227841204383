import { useMutation } from '@tanstack/react-query';
import { ApiResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { useNavigate } from 'react-router-dom';

export const useUpdateTool = () => {
	const navigate = useNavigate();
	return useMutation<
		ApiResponse<number>,
		unknown,
		{ id: string; data: FormData }
	>({
		mutationKey: [QueryKeys.UPDATE_TOOL],
		mutationFn: payload =>
			API.put(`/api/admin/tools/${payload.id}`, payload.data),
		onSuccess(data) {
			if (data.success) {
				Alerter.success(data.messages?.[0]?.message);
				navigate(-1);
			} else {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Tool update was not successful',
				);
			}
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while updating tool',
			);
		},
	});
};
