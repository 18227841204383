export const navLinkItems = [
	{
		name: 'Tools Directory',
		path: '/',
		iconName: 'users',
	},
	{
		name: 'Dashboard',
		path: '/dashboard',
		iconName: 'dashboard',
		isSoon: true,
	},
];
