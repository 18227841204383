import {
	Button,
	Center,
	HStack,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React from 'react';
import { RenderTd, RenderTh } from '../../../components';
import { IconWithTooltip } from './IconWithTooltip';
import { FeaturedStatus } from './FeaturedStatus';
import { ToolItemData } from '../types';

interface ToolDirectoryTableProps {
	isDraft?: boolean;
	headers: string[];
	selectToolId: (id: number) => void;
	navigateOnEdit: (id: number) => void;
	isLoading: boolean;
	data: ToolItemData[];
	onPublishTool?: (id: number) => void;
	selectedToolId: number;
	publishLoading: boolean;
	onOpenRemoveModal: VoidFunction;
}

export const ToolDirectoryTable: React.FC<ToolDirectoryTableProps> = ({
	headers,
	selectToolId,
	navigateOnEdit,
	isDraft = false,
	isLoading,
	data,
	selectedToolId,
	publishLoading,
	onPublishTool,
	onOpenRemoveModal,
}) => {
	return (
		<TableContainer
			w="100%"
			border="1px"
			borderColor="gray.200"
			borderTopRadius="lg"
			boxShadow="none">
			<Table variant="simple">
				<Thead>
					<Tr>
						{headers.map((title, index) => (
							<RenderTh
								key={index}
								color="gray.600"
								value={title}
								bgColor="gray.50"
								letterSpacing="0px"
								borderBottomWidth="1px"
								borderBottomColor="gray.200"
								textTransform="uppercase"
								fontSize="12px"
								lineHeight="18px"
								fontWeight={600}
								minW={title.length ? '100px' : 'auto'}
								w={title.length ? 'auto' : '68px'}
							/>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{isLoading ? (
						<Tr bg="white">
							<Td
								colSpan={isDraft ? 6 : 5}
								borderBottomWidth="0px">
								<Center>
									<Spinner />
								</Center>
							</Td>
						</Tr>
					) : (
						data.map((row, index) => {
							const isLastRow = data.length - 1 === index;
							return (
								<Tr key={row.id} bg="white">
									<RenderTd
										value={row.name}
										borderBottomWidth={
											isLastRow ? '0px' : '1px'
										}
										borderBottomColor="gray.200"
										p="12px"
										maxW="200px"
										verticalAlign="top"
										valueProps={{
											noOfLines: 2,
											color: 'gray.900',
											fontWeight: 500,
										}}
									/>
									<RenderTd
										value={row.category}
										borderBottomWidth={
											isLastRow ? '0px' : '1px'
										}
										borderBottomColor="gray.200"
										p="12px"
										maxW="200px"
										verticalAlign="top"
										valueProps={{
											noOfLines: 2,
											color: 'gray.600',
										}}
									/>
									<RenderTd
										value={row.websiteUrl}
										borderBottomWidth={
											isLastRow ? '0px' : '1px'
										}
										borderBottomColor="gray.200"
										p="12px"
										maxW="200px"
										verticalAlign="top"
										valueProps={{
											noOfLines: 2,
											color: 'gray.600',
										}}
									/>
									{/* <RenderTd value="Featured" /> */}
									<Td
										p="12px"
										borderBottomColor="gray.200"
										borderBottomWidth={
											isLastRow ? '0px' : '1px'
										}>
										<FeaturedStatus
											isFeatured={row.isFeatured}
										/>
									</Td>
									{/* <RenderTd
										value={'description'}
										borderBottomWidth={
											isLastRow ? '0px' : '1px'
										}
										borderBottomColor="gray.200"
										p="12px"
										w="100%"
										minW="200px"
										verticalAlign="top"
										valueProps={{
											noOfLines: 2,
											color: 'gray.600',
											whiteSpace: 'break-spaces',
										}}
									/> */}
									{/* {isDraft ? (
										<Td
											p="12px"
											borderBottomWidth={
												isLastRow ? '0px' : '1px'
											}
											borderBottomColor="gray.200"
											verticalAlign="top"></Td>
									) : null} */}
									<Td
										p="12px"
										borderBottomWidth={
											isLastRow ? '0px' : '1px'
										}
										borderBottomColor="gray.200"
										verticalAlign="top">
										<HStack spacing="12px">
											{isDraft ? (
												<Button
													variant="outline"
													size="xxs"
													isLoading={
														selectedToolId ===
															row.id &&
														publishLoading
													}
													onClick={() => {
														selectToolId(row.id);
														onPublishTool?.(row.id);
													}}>
													Publish
												</Button>
											) : null}
											<IconWithTooltip
												label="Edit"
												iconName="edit"
												placement="bottom"
												onClick={() =>
													navigateOnEdit(row.id)
												}
											/>
											<IconWithTooltip
												label={
													isDraft
														? 'Delete'
														: 'Delete/Unpublish'
												}
												iconName="trash"
												placement="left"
												onClick={() => {
													selectToolId(row.id);
													onOpenRemoveModal();
												}}
											/>
										</HStack>
									</Td>
								</Tr>
							);
						})
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
