import { useQuery } from '@tanstack/react-query';
import { ApiResponse, ToolResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';

export const useFetchToolById = (id?: string) => {
	return useQuery<ApiResponse<ToolResponse>>({
		queryKey: [QueryKeys.FETCH_TOOL_BY_ID, id],
		queryFn: () => API.get(`/api/admin/tools/${id}`, {}),
		enabled: !!id,
	});
};
