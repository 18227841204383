import React, { useCallback, useMemo } from 'react';
import {
	Avatar,
	Box,
	Flex,
	Menu,
	MenuButton,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';
import { useLogout } from '../../../queries';
import { RootStore } from '../../../store/createStore';
import { UserMenu } from './UserMenu';

interface SidebarFooterProps {}

export const SidebarFooter: React.FC<SidebarFooterProps> = () => {
	const logout = useLogout();

	const { token, user } = useSelector((state: RootStore) => state.auth);
	const { accessToken, refreshToken } = token;
	const { firstName, lastName, thumbnailUrl } = user;

	const {
		isOpen: isUserMenuOpen,
		onClose: onCloseUserMenu,
		onToggle: onToggleUserMenu,
	} = useDisclosure();

	const onLogout = useCallback(
		() =>
			logout.mutate(
				{
					accessToken,
					refreshToken,
				},
				{
					onSuccess: () => {
						onCloseUserMenu();
					},
				},
			),
		[accessToken, refreshToken, onCloseUserMenu, logout],
	);

	const userName = useMemo(
		() => `${firstName || 'unknown'} ${lastName || ''}`,
		[firstName, lastName],
	);

	return (
		<Menu
			closeOnSelect={false}
			matchWidth={true}
			isLazy={true}
			isOpen={isUserMenuOpen}
			onClose={onCloseUserMenu}>
			{/* {isLoading ? (
						<Center>
							<Spinner size="lg" color="white" />
						</Center>
					) : ( */}
			<Box>
				<MenuButton onClick={onToggleUserMenu}>
					<Flex
						bg={{
							base: 'transparent',
							md: isUserMenuOpen ? 'primary.200' : 'transparent',
						}}
						p={{ base: '0px', md: '4px' }}
						borderRadius="lg"
						align="center"
						justify="space-between"
						columnGap="8px"
						transition="background 0.3s ease">
						<Avatar
							src={thumbnailUrl || ''}
							name={userName}
							size={{ base: 'xs', md: 'sm' }}
							p="0"
						/>

						<Text
							display={{ base: 'none', md: 'block' }}
							color="gray.900"
							fontSize="xs"
							fontWeight={600}
							maxW="120px">
							{userName}
						</Text>
						<Box
							display={{ base: 'none', md: 'block' }}
							transform="auto"
							transition="transform 0.3s ease"
							rotate={isUserMenuOpen ? '-180deg' : '0deg'}>
							<Icon
								name="chevron-up"
								width="20px"
								height="20px"
								color={colors.gray[700]}
							/>
						</Box>
					</Flex>
				</MenuButton>
			</Box>
			{/* )} */}
			<UserMenu onLogout={onLogout} logoutLoading={logout.isPending} />
		</Menu>
	);
};
