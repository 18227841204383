import 'virtual:svg-icons-register';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/createStore';
import { queryClient } from './constants';
import { theme } from './theme';
import App from './App';
import './fonts.css';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<QueryClientProvider client={queryClient}>
				<ChakraProvider theme={theme}>
					<App />
				</ChakraProvider>
			</QueryClientProvider>
		</PersistGate>
	</Provider>,
);
