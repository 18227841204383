import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const loginSchema = yup.object().shape({
	email: yup.string().required(ErrorMessages.REQUIRED),
	password: yup.string().required(ErrorMessages.REQUIRED),
});

export type LoginForm = yup.InferType<typeof loginSchema>;
export const loginResolver = yupResolver(loginSchema);
