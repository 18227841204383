import React from 'react';
import {
	Box,
	Button,
	ButtonProps,
	HStack,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalFooterProps,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { colors } from '../theme/colors';

interface RemoveModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	removeButtonText: string;
	removeButtonProps?: ButtonProps;
	bodyMessage: string;
	cancelButtonProps?: ButtonProps;
	includeAlternativeAction?: boolean;
	alternativeButtonText?: string;
	alternativeButtonProps?: ButtonProps;
	modalFooterProps?: ModalFooterProps;
}

export const RemoveModal: React.FC<RemoveModalProps> = ({
	isOpen,
	onClose,
	removeButtonText,
	removeButtonProps,
	bodyMessage,
	modalFooterProps,
	cancelButtonProps,
	alternativeButtonText,
	alternativeButtonProps,
	includeAlternativeAction = false,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" isCentered={true}>
			<ModalOverlay bg={`${colors.primary[800]}80`} />
			<ModalContent borderRadius="lg" boxShadow="none">
				<ModalCloseButton
					color="gray.600"
					fontSize="10px"
					autoFocus={false}
					_focusVisible={{ boxShadow: 'none' }}
					_hover={{
						bg: { base: 'transparent', md: 'gray.200' },
					}}
					_active={{}}
				/>
				<ModalBody mt="32px" p="16px 16px 20px 16px">
					<VStack spacing={2}>
						<Box w="56px" h="56px">
							<Image src="/warning-mark.png" w="100%" h="100%" />
						</Box>
						<Text
							color="gray.600"
							variant="sm"
							textAlign="center"
							maxW={{ base: 'auto', md: '311px' }}>
							{bodyMessage}
						</Text>
					</VStack>
				</ModalBody>

				<ModalFooter
					p="0px 20px 20px 20px"
					justifyContent="center"
					columnGap="16px"
					{...modalFooterProps}>
					<Button
						variant="outline-gray"
						size="xs"
						onClick={onClose}
						{...cancelButtonProps}>
						Cancel
					</Button>
					<HStack spacing="12px">
						<Button
							variant="solid"
							size="xs"
							colorScheme="red"
							{...removeButtonProps}>
							{removeButtonText}
						</Button>
						{includeAlternativeAction ? (
							<Button
								variant="solid"
								size="xs"
								colorScheme="primary"
								{...alternativeButtonProps}>
								{alternativeButtonText}
							</Button>
						) : null}
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
