import { useMutation } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponseEmpty, ToolStatus } from '../types';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';

export const useUpdatePublishStatus = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ id: number; status: ToolStatus }
	>({
		mutationKey: [QueryKeys.UPDATE_PUBLISH_STATUS],
		mutationFn: payload => {
			const { id, status } = payload;
			return API.patch(`/api/admin/tools/${id}/status`, { status });
		},
		onSuccess: data => {
			if (!data.success) {
				return Alerter.error(
					data.messages[0]?.message ||
						'Something went wrong. Please try again',
				);
			}
			Alerter.success(data.messages[0]?.message);
		},
	});
};
