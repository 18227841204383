import React from 'react';
import {
	Box,
	Flex,
	FlexProps,
	IconButton,
	VStack,
	useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { navLinkItems } from '../../../constants';
import { NavItem } from './NavItem';
import { useLocation } from 'react-router-dom';
import { SidebarFooter } from './SidebarFooter';

interface MobileNavProps extends FlexProps {
	onOpen: () => void;
}

export const MobileNav: React.FC<MobileNavProps> = ({ onOpen, ...rest }) => {
	const location = useLocation();
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 24 }}
			maxW="56px"
			direction="column"
			py="16px"
			height="100vh"
			alignItems="start"
			bg={useColorModeValue('gray.50', 'black')}
			borderRightWidth="1px"
			borderRightColor="gray.200"
			justifyContent="flex-start"
			{...rest}>
			<IconButton
				variant="outline"
				colorScheme="gray"
				borderRadius="md"
				w="24px"
				h="24px"
				onClick={onOpen}
				aria-label="open menu"
				icon={<HamburgerIcon color="black" />}
				_hover={{ bg: 'transparent' }}
				_active={{ bg: 'transparent' }}
			/>
			<VStack mt="24px" h="100%">
				{navLinkItems.map((link, index) => (
					<NavItem
						key={index}
						isActive={link.path === location.pathname}
						path={link.path}
						iconName={link.iconName}
						name={link.name}
						onlyIcon={true}
					/>
				))}
				<Box mt="auto">
					<SidebarFooter />
				</Box>
			</VStack>
		</Flex>
	);
};
