import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './components';

export const MainLayout: React.FC = () => {
	return (
		<Flex minH="100vh" bg="gray.200">
			<Sidebar />
			<Box flex={1} transition=".3s" bg="white" h="100vh" overflow="auto">
				<Outlet />
			</Box>
		</Flex>
	);
};
