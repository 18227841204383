import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse, RefreshResponse } from '../../types';

const initialState: LoginResponse = {
	token: { accessToken: '', accessTokenExpiration: '', refreshToken: '' },
	user: {
		email: '',
		id: 0,
		roles: [],
		firstName: '',
		lastName: null,
		avatarUrl: null,
		thumbnailUrl: null,
	},
};

const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthStatus: (_state, action: PayloadAction<LoginResponse>) => {
			return action.payload;
		},
		refreshToken: (state, action: PayloadAction<RefreshResponse>) => {
			state.token = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});

export const { setAuthStatus, logout, refreshToken } = authReducer.actions;

export default authReducer.reducer;
