import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import API from '../api/api.base';
import { ApiResponseEmpty } from '../types';
import { QueryKeys } from '../constants';
import { logout } from '../store/reducers/auth.reducer';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ accessToken: string; refreshToken: string }
	>({
		mutationKey: [QueryKeys.LOGOUT],
		mutationFn: payload => API.post('/api/auth/logout', payload),
		onSuccess() {
			dispatch(logout());
			navigate('/login');
		},
	});
};
