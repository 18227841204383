export const BODY_TABS = ['Drafts', 'Published'];

export const TOOLS_DIRECTORY_TABLE_HEADERS = [
	'tool name',
	'tool category',
	'website url',
	'status',
	// 'website description',
	'',
];
