import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ApiResponseEmpty, ToolStatus } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';

export const useCreateTool = (status: ToolStatus) => {
	const navigate = useNavigate();
	return useMutation<ApiResponseEmpty, unknown, FormData>({
		mutationKey: [QueryKeys.CREATE_TOOL, status],
		mutationFn: payload => API.post('/api/admin/tools', payload),
		onSuccess(data) {
			if (data.success) {
				Alerter.success(data.messages?.[0]?.message);
				navigate(-1);
			} else {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Tool creation was not successful',
				);
			}
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while creating tool',
			);
		},
	});
};
