import React from 'react';
import {
	IconButton,
	Text,
	HStack,
	Button,
	chakra,
	TextProps,
	SelectProps,
	StackProps,
	Flex,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';
import { usePagination } from './hooks';
import { Select } from '../Select/Select';
import { SelectOption } from '../../types';

const DOTS = '...';
const PAGE_SIZES = [
	{ label: '12 / Pages', value: '12' },
	{ label: '24 / Pages', value: '24' },
	{ label: '36 / Pages', value: '36' },
	{ label: '48 / Pages', value: '48' },
	{ label: '60 / Pages', value: '60' },
];

interface PaginationProps {
	totalCount: number;
	pageSize: number;
	setCurrentPage: (value: number) => void;
	currentPage: number;
	setPageSize: (value: number) => void;
	pagesTextProps?: TextProps;
	selectProps?: SelectProps;
	defaultPaginationProps?: StackProps;
	siblingCount?: number;
}

export const Pagination: React.FC<PaginationProps> = ({
	totalCount,
	pageSize,
	setCurrentPage,
	currentPage,
	setPageSize,
	pagesTextProps,
	defaultPaginationProps,
	siblingCount = 1,
}) => {
	const pageCount = Math.ceil(totalCount / pageSize);
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	return (
		<HStack spacing="12px" m={4} {...defaultPaginationProps}>
			<Text
				flexShrink="0"
				fontSize="sm"
				color="gray.600"
				{...pagesTextProps}>
				Showing{' '}
				{totalCount === 0 ? 0 : currentPage * pageSize - pageSize + 1} -{' '}
				{currentPage * pageSize > totalCount
					? totalCount
					: currentPage * pageSize}{' '}
				of{' '}
				<chakra.span color="gray.900" fontWeight={600}>
					{totalCount}
				</chakra.span>
			</Text>
			<Select
				menuPlacement="auto"
				value={PAGE_SIZES.find(el => el.value === pageSize.toString())}
				onChange={option => {
					setPageSize(Number(+(option as SelectOption).value));
					setCurrentPage(1);
				}}
				options={PAGE_SIZES}
				controlProps={{
					h: '37px',
					minH: '37px',
					w: '140px',
					bg: 'gray.50',
				}}
				optionProps={{
					justifyContent: 'center',
				}}
				formControlProps={{
					w: '140px',
				}}
			/>

			{pageCount > 1 ? (
				<HStack spacing={0} ml="auto">
					<IconButton
						aria-label="Previous Page"
						variant="ghost"
						onClick={() => setCurrentPage(currentPage - 1)}
						isDisabled={currentPage === 1}
						w="36px"
						h="32px"
						borderRadius="0px"
						borderLeftRadius="base"
						borderWidth="1px"
						borderColor="gray.300"
						icon={
							<Icon
								name="chevron-left"
								width="12px"
								height="12px"
								color={colors.gray[500]}
							/>
						}
					/>
					{paginationRange.map(
						(pageNumber: string | number, index) => {
							const isSelected = pageNumber === currentPage;
							if (pageNumber === DOTS) {
								return (
									<Flex
										key={index}
										w="34px"
										h="32px"
										justify="center"
										align="center"
										borderWidth="1px"
										borderColor={
											isSelected
												? 'primary.700'
												: 'gray.300'
										}
										borderLeftWidth="0px">
										<Text
											variant="sm"
											p="0px 7.5px"
											lineHeight="12px"
											textAlign="center"
											color="gray.500">
											&#8230;
										</Text>
									</Flex>
								);
							}

							return (
								<Button
									key={index}
									variant="ghost"
									h="32px"
									w="34px"
									minW="34px"
									borderRadius="0px"
									px="12px"
									fontWeight={500}
									color={isSelected ? 'white' : 'gray.500'}
									backgroundColor={
										isSelected
											? 'primary.700'
											: 'transparent'
									}
									borderWidth="1px"
									borderColor={
										isSelected ? 'primary.700' : 'gray.300'
									}
									borderLeftWidth="0px"
									_hover={{
										backgroundColor: isSelected
											? 'primary.700'
											: 'primary.50',
									}}
									_active={{
										backgroundColor: isSelected
											? 'primary.700'
											: 'primary.100',
									}}
									onClick={() => setCurrentPage(+pageNumber)}>
									{pageNumber}
								</Button>
							);
						},
					)}
					<IconButton
						aria-label="Next page"
						variant="ghost"
						onClick={() => setCurrentPage(currentPage + 1)}
						isDisabled={currentPage === pageCount}
						w="36px"
						h="32px"
						borderRadius="0px"
						borderRightRadius="base"
						borderWidth="1px"
						borderColor="gray.300"
						borderLeftWidth="0px"
						icon={
							<Icon
								name="chevron-right"
								width="12px"
								height="12px"
								color={colors.gray[500]}
							/>
						}
					/>
				</HStack>
			) : null}
		</HStack>
	);
};
