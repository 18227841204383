import { FC } from 'react';
import { TableCellProps, Td, Text, TextProps } from '@chakra-ui/react';

interface RenderTdProps extends TableCellProps {
	value: string | number;
	valueProps?: TextProps;
}

export const RenderTd: FC<RenderTdProps> = ({
	value,
	valueProps,
	...props
}) => {
	return (
		<Td fontSize="sm" p="12px" {...props}>
			<Text sx={{ textWrap: 'wrap' }} {...valueProps}>
				{value}
			</Text>
		</Td>
	);
};
