import { createStandaloneToast } from '@chakra-ui/react';
import { ToastBody } from '../components';
import { PayloadError } from '../types';
import { theme } from '../theme';
import { colors } from '../theme/colors';

const toast = createStandaloneToast({ theme });

class AlerterService {
	success(message: string) {
		toast.toast({
			status: 'success',
			duration: 5000,
			isClosable: true,
			position: 'top',
			containerStyle: {
				position: 'absolute',
				top: '70px',
				minW: 'auto',
			},
			render: ({ onClose }) => (
				<ToastBody
					iconName="check-circle"
					colorScheme="green"
					iconProps={{
						color: colors.green[800],
					}}
					onClose={onClose}>
					{message}
				</ToastBody>
			),
		});
	}
	error(message: { errors: PayloadError[] } | string) {
		if (typeof message === 'string') {
			toast.toast({
				status: 'error',
				duration: 5000,
				isClosable: true,
				position: 'top',
				containerStyle: {
					position: 'absolute',
					top: '70px',
					minW: 'auto',
				},
				render: ({ onClose }) => (
					<ToastBody
						colorScheme="red"
						//TODO change icon to something else
						iconName="check-circle"
						iconProps={{
							color: colors.red[800],
						}}
						onClose={onClose}>
						{message}
					</ToastBody>
				),
			});
		}
	}
	warning(message: string) {
		toast.toast({
			status: 'warning',
			duration: 5000,
			isClosable: true,
			position: 'top',
			containerStyle: {
				position: 'absolute',
				top: '70px',
				minW: 'auto',
			},
			render: ({ onClose }) => (
				<ToastBody
					colorScheme="yellow"
					//TODO change icon to something else
					iconName="check-circle"
					iconProps={{
						color: colors.yellow[800],
					}}
					onClose={onClose}>
					{message}
				</ToastBody>
			),
		});
	}
	info(message: string) {
		toast.toast({
			status: 'info',
			variant: 'subtle',
			duration: 5000,
			isClosable: true,
			position: 'top',
			containerStyle: {
				position: 'absolute',
				top: '70px',
				minW: 'auto',
			},
			render: ({ onClose }) => (
				<ToastBody
					colorScheme="primary"
					//TODO change icon to something else
					iconName="check-circle"
					iconProps={{
						color: colors.primary[800],
					}}
					onClose={onClose}>
					{message}
				</ToastBody>
			),
		});
	}
}
export const Alerter = new AlerterService();
