import React from 'react';
import { Tag } from '@chakra-ui/react';

interface IFeaturedStatusProps {
	isFeatured: boolean;
}

export const FeaturedStatus: React.FC<IFeaturedStatusProps> = ({
	isFeatured,
}) => {
	return (
		<Tag
			fontWeight={500}
			fontSize="12px"
			color={isFeatured ? 'primary.800' : 'gray.900'}
			bg={isFeatured ? 'primary.100' : 'gray.100'}>
			{isFeatured ? 'Featured' : 'Default'}
		</Tag>
	);
};
