import React from 'react';
import { Box, Button, Container, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { InputText } from '../../components';
import { loginResolver, LoginForm } from '../../validation';
import { useLogin } from './queries';

export const LoginPage: React.FC = () => {
	const { mutate, isPending } = useLogin();

	const {
		formState: { errors },
		handleSubmit,
		register,
	} = useForm<LoginForm>({
		resolver: loginResolver,
	});

	const onSubmit = (data: LoginForm) => {
		const { password, email } = data;

		mutate({ login: email, password });
	};

	return (
		<Box bg="gray.50" minH="100vh" pt={24}>
			<Text variant="2xl" fontWeight="600" textAlign="center" mb={6}>
				Tools Directory
			</Text>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
				<Container
					maxW={{ base: 'container.sm', md: '450px' }}
					bg="white"
					boxShadow="base"
					borderRadius="8px"
					padding={8}
					display="flex"
					flexDirection="column"
					gap={5}>
					<Text fontWeight="700" variant="2xl">
						Log in
					</Text>
					<InputText
						label="Email"
						height="42px"
						{...register('email')}
						errorMsg={errors.email?.message}
					/>
					<InputText
						label="Password"
						height="42px"
						{...register('password')}
						errorMsg={errors.password?.message}
						isPassword={true}
					/>
					<Button
						type="submit"
						h="44px"
						fontSize="16px"
						isLoading={isPending}>
						Log In
					</Button>
				</Container>
			</form>
		</Box>
	);
};
