import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RichEditor } from '../../../components';
import { BottomActionsBar, WrapperWithPreviewBox } from '../components';
import { CreateToolPayload, ToolStatus } from '../../../types';
import { useFetchToolById, useCreateTool, useUpdateTool } from '../queries';
import { constructCreateToolPayload, appendEditToolData } from '../../../utils';

interface IToolDescriptionStepProps {
	handleTabsChange: (index: number) => void;
}

export const ToolDescriptionStep: React.FC<IToolDescriptionStepProps> = ({
	handleTabsChange,
}) => {
	const {
		control,
		formState: { errors },
		trigger,
		reset,
		getValues,
		handleSubmit,
	} = useFormContext<CreateToolPayload>();
	const { id } = useParams();
	const draftToolMutation = useCreateTool(ToolStatus.Unpublished);
	const updateToolMutation = useUpdateTool();
	const { data } = useFetchToolById(id);

	const handleNext = async () => {
		const isStepValid = await trigger();
		if (isStepValid) {
			handleTabsChange(2);
		}
	};

	const onDraftSave = (data: CreateToolPayload) => {
		const fd = new FormData();
		constructCreateToolPayload(fd, data);
		fd.append('Status', ToolStatus.Unpublished.toString());
		reset(getValues());
		if (id) {
			appendEditToolData(fd, data);
			updateToolMutation.mutate({ id, data: fd });
		} else {
			draftToolMutation.mutate(fd);
		}
	};

	return (
		<>
			<WrapperWithPreviewBox>
				<Controller
					name="shortDescription"
					control={control}
					render={({ field }) => {
						return (
							<RichEditor
								value={field.value}
								onChange={field.onChange}
								label="Tool short description"
								errorMsg={errors.shortDescription?.message}
								defaultValue={data?.value?.shortDescription}
							/>
						);
					}}
				/>
				<Controller
					name="bodyTextShort"
					control={control}
					render={({ field }) => (
						<RichEditor
							value={field.value}
							onChange={field.onChange}
							label="Body text (Short)"
							errorMsg={errors.bodyTextShort?.message}
							defaultValue={data?.value?.bodyTextShort}
						/>
					)}
				/>
				<Controller
					name="bodyText"
					control={control}
					render={({ field }) => (
						<RichEditor
							value={field.value}
							onChange={field.onChange}
							label="Body text (Full length)"
							errorMsg={errors.bodyText?.message}
							defaultValue={data?.value?.bodyText}
						/>
					)}
				/>
			</WrapperWithPreviewBox>
			<BottomActionsBar
				onBack={() => handleTabsChange(0)}
				includeNextArrow={true}
				confirmLabel="Next"
				onConfirm={handleNext}
				draftLoading={
					updateToolMutation.isPending || draftToolMutation.isPending
				}
				onDraftSave={() => handleSubmit(onDraftSave)()}
			/>
		</>
	);
};
