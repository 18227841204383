import React, { useEffect } from 'react';
import './RichEditor.module.css';
import {
	Box,
	FormErrorMessage,
	FormControl,
	FormLabel,
} from '@chakra-ui/react';
import { useEditor, EditorContent } from '@tiptap/react';
import { tipTapExtensions } from '../../constants';
import { MenuBar } from './MenuBar';

interface IRichEditorProps {
	label?: string;
	value: string;
	onChange: (value: string) => void;
	errorMsg?: string;
	defaultValue?: string;
}

export const RichEditor: React.FC<IRichEditorProps> = ({
	label,
	onChange,
	value = '',
	errorMsg,
	defaultValue,
}) => {
	const editor = useEditor({
		extensions: tipTapExtensions,
		content: value,
		onUpdate({ editor }) {
			onChange(editor.getHTML());
		},
	});

	useEffect(() => {
		if (defaultValue) {
			editor?.commands.setContent(defaultValue);
		}
	}, [defaultValue, editor]);

	return (
		<FormControl isInvalid={!!errorMsg}>
			{label ? (
				<FormLabel fontSize="14px" fontWeight={500}>
					{label}
				</FormLabel>
			) : null}
			<Box
				sx={{
					'*': { all: 'revert' },
					'> div': { px: '8px', maxH: '500px', overflow: 'auto' },
				}}
				border="1px solid"
				borderColor="gray.300"
				borderRadius="8px">
				<MenuBar editor={editor} />
				<EditorContent editor={editor} />
			</Box>
			<FormErrorMessage>{errorMsg}</FormErrorMessage>
		</FormControl>
	);
};
