import React, { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { RemoveModal } from '../../../modals';

interface IBlockerDialog {
	isDirty: boolean;
}

export const BlockerDialog: React.FC<IBlockerDialog> = ({ isDirty }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			isDirty && currentLocation.pathname !== nextLocation.pathname,
	);

	const onCloseAction = useCallback(() => {
		if (blocker.state === 'blocked') {
			blocker.reset();
			onClose();
		}
	}, [blocker]);

	const onConfirm = useCallback(() => {
		if (blocker.state === 'blocked') {
			blocker.proceed();
		}
	}, [blocker]);

	useEffect(() => {
		if (blocker.state === 'blocked') {
			onOpen();
		}
	}, [blocker]);

	return (
		<RemoveModal
			isOpen={isOpen}
			onClose={onCloseAction}
			removeButtonText="Discard"
			bodyMessage="Discard this action? All progress would be lost."
			removeButtonProps={{
				onClick: () => onConfirm(),
			}}
		/>
	);
};
