import React, { PropsWithChildren } from 'react';
import { Button } from '@chakra-ui/react';

interface IFollowIndexButtonProps extends PropsWithChildren {
	isSelected: boolean;
	onClick: VoidFunction;
}

export const FollowIndexButton: React.FC<IFollowIndexButtonProps> = ({
	isSelected,
	onClick,
	children,
}) => {
	return (
		<Button
			color={isSelected ? 'primary.700' : 'gray.500'}
			border="1px solid"
			borderColor={isSelected ? 'primary.700' : 'gray.300'}
			bg={isSelected ? 'primary.50' : 'gray.50'}
			fontSize="14px"
			py={1}
			px={2}
			height="auto"
			variant="unstyled"
			borderRadius="8px"
			_hover={{ bg: 'primary.200' }}
			onClick={onClick}>
			{children}
		</Button>
	);
};
