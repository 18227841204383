import { useMutation } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';

export const useDeleteTool = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationKey: [QueryKeys.DELETE_TOOL],
		mutationFn: payload => API.delete(`/api/admin/tools/${payload}`, {}),
		onSuccess: data => {
			if (!data.success) {
				return Alerter.error(
					data.messages[0]?.message ||
						'Something went wrong. Please try again',
				);
			}
			Alerter.success(data.messages[0]?.message);
		},
	});
};
