import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Text, HStack, Box } from '@chakra-ui/react';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';

interface NavItemProps {
	name: string;
	iconName: string;
	path: string;
	isActive: boolean;
	onlyIcon?: boolean;
	isSoon?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
	name,
	iconName,
	path,
	isActive,
	onlyIcon = false,
	isSoon,
}) => {
	return (
		<Link
			width="inherit"
			py="6px"
			px={onlyIcon ? '2px' : '8px'}
			as={RouterLink}
			transition=".3s"
			bg={{
				base: 'transparent',
				md: isActive ? 'primary.50' : 'transparent',
			}}
			borderBottomWidth={{
				base: onlyIcon ? '0px' : isActive ? '1px' : '0px',
				md: '0px',
			}}
			borderBottomColor={{
				base: onlyIcon ? 'unset' : isActive ? 'primary.700' : 'unset',
				md: 'unset',
			}}
			borderRadius={{ base: '0px', md: 'lg' }}
			to={isSoon ? '' : path}
			_hover={{
				textDecoration: 'none',
				cursor: isSoon ? 'default' : 'pointer',
			}}>
			<HStack align="center" spacing={3}>
				<Icon
					name={iconName}
					width="20px"
					height="20px"
					color={isActive ? colors.primary[700] : colors.gray[900]}
				/>
				{onlyIcon ? null : (
					<Text
						color={isActive ? 'primary.700' : 'gray.900'}
						variant={{ base: 'xl', md: 'base' }}
						fontWeight={600}>
						{name}
					</Text>
				)}
				{isSoon ? (
					<Box px={2} bg="gray.300" borderRadius="50px">
						<Text
							fontSize="12px"
							lineHeight="16px"
							color="gray.700">
							Soon
						</Text>
					</Box>
				) : null}
			</HStack>
		</Link>
	);
};
