import * as yup from 'yup';
import { ErrorMessages } from './messages';

export const createToolSchemas = [
	yup.object({
		seoTitle: yup.string().required(ErrorMessages.REQUIRED),
		seoSchema: yup.string(),
		toolName: yup.string().required(ErrorMessages.REQUIRED),
		websiteUrl: yup
			.string()
			.url('Should be a valid url')
			.required(ErrorMessages.REQUIRED),
		slug: yup
			.string()
			.matches(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/, {
				message: 'Not a valid slug',
				excludeEmptyString: true,
			})
			.required(ErrorMessages.REQUIRED),
		categoryId: yup.mixed().required(ErrorMessages.REQUIRED),
		tags: yup
			.mixed()
			.test(
				'required',
				'Please select at least one tag',
				function (value) {
					return !!value;
				},
			),
	}),
	yup.object({
		shortDescription: yup.string().required(ErrorMessages.REQUIRED),
		bodyTextShort: yup.string().required(ErrorMessages.REQUIRED),
		bodyText: yup.string().required(ErrorMessages.REQUIRED),
	}),
	yup.object({
		images: yup
			.mixed()
			.test(
				'required',
				'Please select at least one image',
				function (value) {
					if (!value) {
						return false;
					}
					// @ts-ignore
					return !!value?.length;
				},
			),
		thumbnail: yup
			.mixed()
			.nullable()
			.test('required', 'Please select thumbnail', function (value) {
				return !!value;
			}),
	}),
];
