import { useQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { QueryKeys } from '../../../constants';
import { ApiResponse } from '../../../types';
import { ToolsDirectoryTableResponse } from '../types';

export const useGetTools = (
	search?: string,
	status?: number,
	categoryId?: number,
	offset?: number,
	limit?: number,
) => {
	return useQuery<ApiResponse<ToolsDirectoryTableResponse>>({
		queryKey: [
			QueryKeys.GET_TOOLS,
			offset,
			limit,
			search,
			status,
			categoryId,
		],
		queryFn: () =>
			API.get('/api/admin/tools', {
				offset,
				limit,
				search: search || undefined,
				status: status || undefined,
				categoryId: categoryId || undefined,
			}),
		refetchOnWindowFocus: false,
		// enabled: isFetchEnabled,
	});
};
