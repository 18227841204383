import { extendTheme, theme as base } from '@chakra-ui/react';
import { Text, Input, Button, Textarea } from './components';
import { colors } from './colors';
import { shadows } from './shadows';

export const theme = extendTheme({
	colors,
	shadows,
	fonts: {
		body: `'Inter', ${base.fonts.body}`,
	},
	components: { Text, Input, Button, Textarea },
	styles: {
		global: {
			body: {
				minH: '100vh',
			},
		},
	},
});
