import React from 'react';
import { Box, Image, IconButton } from '@chakra-ui/react';
import { Icon } from '../../../components';

export const PreviewImage: React.FC<{
	src: string;
	onRemove: VoidFunction;
}> = ({ src, onRemove }) => {
	return (
		<Box
			w="110px"
			h="100px"
			borderRadius="8px"
			border="1px solid"
			borderColor="gray.200"
			position="relative">
			<IconButton
				aria-label="remove uploaded image"
				variant="unstyled"
				position="absolute"
				height="auto"
				right="5px"
				top="5px"
				onClick={onRemove}>
				<Icon name="close" width="12px" height="12px" />
			</IconButton>
			<Image
				src={src}
				height="80px"
				w="100%"
				mt="20px"
				borderBottomRadius="8px"
			/>
		</Box>
	);
};
